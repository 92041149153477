import { Typography, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '../../assets/img/infoIcon.svg';

const Textarea = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
    borderColor: 'transparent',
  },
  '& .MuiInputBase-inputMultiline': {
    fontSize: '0.875em',
    fontWeight: 400,
  },
  '& .MuiOutlinedInput-root': {
    minHeight: '32vh',
    alignItems: 'flex-start',
    '& fieldset': {
      borderColor: theme.palette.border.lightWhiteGrey,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.border.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.selected.greenSelect,
    },
    '&.Mui-disabled fieldset': {
      borderColor: theme.palette.border.darkGrey,
    },
  },
}));

const StyledInfoIconContainer = styled('div')({
  marginLeft: '0.6em',
  display: 'flex',
  alignItems: 'center',
});

const StyledInfoIcon = styled(InfoIcon)({
  width: '1em',
  height: '1em',
});

const StyledBox = styled('div')(({ theme }) => ({
  padding: '1em',
  fontSize: '0.875em',
  fontWeight: 700,
  backgroundColor: theme.customColors.configLighterDark,
}));

const StyledFlexBox = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '3em',
  padding: '1em',
  alignItems: 'center',
  backgroundColor: theme.customColors.dullDark,
  borderTop: `0.0625em solid ${theme.customColors.wrapperGrey}`,
  borderBottom: `0.0625em solid ${theme.customColors.wrapperGrey}`,
}));

const StyledTypographyTooltip = styled(Typography)({
  fontSize: '1em',
});

const StyledTypography = styled('div')({
  width: '33.33%',
  display: 'flex',
  alignItems: 'center',
});

const StyledTitlesParams = styled('div')({
  fontSize: '0.875em',
});

const StyledSliderBox = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '3em',
  padding: '1em',
  borderBottom: `0.0625em solid ${theme.customColors.wrapperGrey}`,
}));

const StyledFlexContainer = styled('div')({
  display: 'flex',
  minHeight: '33vh',
});

const StyledDivDefault = styled('div')(({ theme }) => ({
  width: '30%',
  padding: '1em',
  borderRight: `0.0625em solid ${theme.customColors.wrapperGrey}`,
}));

const StyledDivAdditional = styled('div')({
  width: '70%',
});

const StyledDefaultTitle = styled('div')({
  fontSize: '1em',
  fontWeight: 600,
  paddingBottom: '1em',
});

const StyledParagraph = styled('p')({
  paddingBottom: '1em',
});

const StyledAdditionalTitle = styled('div')({
  fontSize: '1em',
  fontWeight: 700,
  padding: '1em 1em 0 1em',
});

const StyledButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1em',
  justifyContent: 'flex-end',
  borderTop: `0.0625em solid ${theme.customColors.wrapperGrey}`,
  gap: '1em',
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.customColors.saveGreen,
  borderRadius: '1.25em',
  color: theme.palette.common.black,
  padding: '0.5em 2em',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.customColors.saveGreen,
    color: theme.palette.common.black,
  },
}));

const StyledResetButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'inherit',
  color: theme.palette.common.white,
  border: `0.0625em solid ${theme.palette.common.white}`,
  borderRadius: '1.25em',
  padding: '0.5em 2em',
  textTransform: 'none',
}));

const StyledLoadingContainer = styled('div')({
  padding: '2em',
});

const ModalTitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.75rem',
  alignItems: 'center',
});

const ConfirmTypography = styled(Typography)({
  fontWeight: 700,
});

const ModalContentText = styled(Typography)({
  fontWeight: 400,
  lineHeight: '1.19rem',
  fontSize: '0.875rem',
  marginTop: '1rem',
});

const ModalConfirmationText = styled(Typography)({
  fontWeight: 700,
  lineHeight: '1.19rem',
  fontSize: '0.875rem',
  marginTop: '1rem',
});

export {
  Textarea,
  StyledInfoIconContainer,
  StyledBox,
  StyledFlexBox,
  StyledTypography,
  StyledSliderBox,
  StyledFlexContainer,
  StyledDivDefault,
  StyledDivAdditional,
  StyledDefaultTitle,
  StyledParagraph,
  StyledAdditionalTitle,
  StyledTypographyTooltip,
  StyledButtonContainer,
  StyledSubmitButton,
  StyledResetButton,
  StyledLoadingContainer,
  StyledInfoIcon,
  StyledTitlesParams,
  ModalTitleWrapper,
  ConfirmTypography,
  ModalContentText,
  ModalConfirmationText,
};
