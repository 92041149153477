import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import MESSAGE_STRINGS from '../../constants/en-us';
import { FILE_STATUS, FILE_CONTENT_TYPE_PDF } from '../../constants/index';
import UploadFile from '../../components/UploadFile';
import {
  getPreSignedUrlForGenCI,
  updateFileConfigData,
  uploadFileToS3,
} from '../../utils/apiHelpers';
import { getInfoContentByFileStatus } from '../../utils/helpers';

function GenCIUpload({
  entityHierarchy,
  entityName,
  fileStatus,
  refetchConfigFileData,
}) {
  const [uploadUserInfo, setUploadUserInfo] = useState(null);
  const [infoContent, setInfoContent] = useState(
    MESSAGE_STRINGS['GenCI.uploadInfo.content'],
  );

  const {
    isLoading: isUpdateFileConfigUrlLoading,
    isError: isUpdateFileConfigUrlError,
    mutate: updateFileConfigAPI,
  } = useMutation(
    (data) => {
      return updateFileConfigData(data);
    },
    {
      onSuccess: async () => {
        refetchConfigFileData();
      },
    },
  );

  const {
    isLoading: isGetUploadUrlLoading,
    isError: isGetUploadUrlError,
    mutate: getUploadUrl,
  } = useMutation(
    (file) => {
      setUploadUserInfo(null);
      return getPreSignedUrlForGenCI({
        entityHierarchy,
        fileName: file.name,
        contentType: FILE_CONTENT_TYPE_PDF,
      });
    },
    {
      onSuccess: async (response, file) => {
        await uploadFileToS3(response.data, file);
        updateFileConfigAPI({
          entityHierarchy,
          entityName,
          fileName: file.name,
          status: FILE_STATUS.IN_PROGRESS,
        });
      },
    },
  );

  useEffect(() => {
    setInfoContent(getInfoContentByFileStatus(fileStatus));
  }, [fileStatus]);

  return (
    <Box
      display="flex"
      data-testid="gen-ci-upload-container"
      flexDirection="column"
    >
      <UploadFile
        entityHierarchy={entityHierarchy}
        getUploadUrl={getUploadUrl}
        uploadTitle={MESSAGE_STRINGS['GenCIUpload.primaryUploadTitle']}
        isLoading={isGetUploadUrlLoading || isUpdateFileConfigUrlLoading}
        isFileUploadError={isGetUploadUrlError || isUpdateFileConfigUrlError}
        infoContent={infoContent}
        uploadUserInfo={uploadUserInfo}
      />
    </Box>
  );
}

GenCIUpload.propTypes = {
  entityHierarchy: PropTypes.string,
  entityName: PropTypes.string,
  fileStatus: PropTypes.string,
  refetchConfigFileData: PropTypes.func,
};

export default GenCIUpload;
