import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from '../StatusIcon';
import { LabelTextContainer, LabelContainer } from './style.js';
import CustomTreeItem from '../RenderTreeData/style';

const CustomTreeItemWithIcon = React.forwardRef(
  function StyledTreeItem(props, ref) {
    const { labelText, node, ...other } = props;

    return (
      <CustomTreeItem
        label={
          <LabelContainer>
            <LabelTextContainer variant="body2">{labelText}</LabelTextContainer>
            <StatusIcon
              data-testid="file-status-icon"
              fileStatus={node?.fileStatus}
            />
          </LabelContainer>
        }
        {...other}
        ref={ref}
      />
    );
  },
);

CustomTreeItemWithIcon.propTypes = {
  labelText: PropTypes.string,
  node: PropTypes.shape({
    entityId: PropTypes.number,
    entityNumber: PropTypes.string,
    entityType: PropTypes.string,
    parentEntityId: PropTypes.number,
    entityName: PropTypes.string,
    entityHierarchy: PropTypes.string,
    hierarchyLevel: PropTypes.number,
    entityChildren: PropTypes.instanceOf(Array),
    entityAutoId: PropTypes.number,
    fileStatus: PropTypes.string,
  }),
};

export default CustomTreeItemWithIcon;
