import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '../../assets/img/switchChecked.svg';
import UncheckIcon from '../../assets/img/switchUnchecked.svg';
import StyledSwitch from './style.js';

export default function SMFSwitch({ checked, onChange, disabled, ...props }) {
  return (
    <StyledSwitch
      data-testid="smf-genci-switch"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      disableRipple
      icon={<UncheckIcon />}
      checkedIcon={<CheckIcon />}
      {...props}
    />
  );
}

SMFSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
