import { Tooltip, styled, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.text.lightYellowishGray,
    backgroundColor: theme.palette.background.eerieBlack,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    border: `1px solid ${theme.customColors.metalGrey}`,
    borderRadius: '0.25rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      backgroundColor: theme.palette.background.eerieBlack,
    },
  },
}));

export default StyledTooltip;
