import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import Accordion from '../../components/SMFAccordion';
import MESSAGE_STRINGS from '../../constants/en-us';
import GeneralTooltip from '../../components/GeneralTooltip';
import SMFSlider from '../../components/SMFSlider';
import {
  Textarea,
  StyledBox,
  StyledFlexBox,
  StyledTypography,
  StyledSliderBox,
  StyledFlexContainer,
  StyledInfoIconContainer,
  StyledDivDefault,
  StyledDivAdditional,
  StyledDefaultTitle,
  StyledParagraph,
  StyledAdditionalTitle,
  StyledTypographyTooltip,
  StyledButtonContainer,
  StyledSubmitButton,
  StyledResetButton,
  StyledLoadingContainer,
  StyledInfoIcon,
  StyledTitlesParams,
  ModalTitleWrapper,
  ConfirmTypography,
  ModalContentText,
  ModalConfirmationText,
} from './style';
import { useRxjsState } from '../../hooks/useRxjsState';
import {
  getLLMFineTuningData,
  configureLLMFineTuningData,
} from '../../utils/apiHelpers';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  getTemperatureValue,
  getTopPValue,
  getKValue,
  getPrompts,
} from '../../utils/helpers';
import GeneralDialog from '../../components/GeneralDialog';
import ModalBody from '../../components/GeneralDialog/GeneralDialogBody';
import Actions from '../../components/ModalActions';
import ExclamationIcon from '../../assets/img/ExclamationIcon.svg';

const LLMConfig = ({ isAccordianEnable = false }) => {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const { rxjsState } = useRxjsState();
  const [currentTemperatureValue, setCurrentTemperatureValue] = useState(null);
  const [currentTopPValue, setCurrentTopPValue] = useState(null);
  const [currentKValue, setCurrentKValue] = useState(null);
  const [config, setConfig] = useState({});
  const [prompts, setPrompts] = useState([]);
  const [additionalPrompts, setAdditionalPrompts] = useState('');
  const [modifiedTemperatureValue, setModifiedTemperatureValue] =
    useState(null);
  const [modifiedTopPValue, setModifiedTopPValue] = useState(null);
  const [modifiedKValue, setModifiedKValue] = useState(null);
  const [refetch, setRefetch] = useState(true);
  const [isOpen, setIsOpen] = useState(isAccordianEnable);
  const [isSubmitConfirmationDialogOpen, setIsSubmitConfirmationDialogOpen] =
    useState(false);
  const [isResetConfirmationDialogOpen, setIsResetConfirmationDialogOpen] =
    useState(false);

  const { isLoading: isLLMFineTuningDataLoading } = useQuery({
    queryFn: () => {
      const queryParams = {
        factoryId: rxjsState.plantId,
      };
      return getLLMFineTuningData(queryParams);
    },
    onSuccess: (res) => {
      setConfig(res);
      setCurrentTemperatureValue(getTemperatureValue(res));
      setCurrentTopPValue(getTopPValue(res));
      setCurrentKValue(getKValue(res));
      setModifiedTemperatureValue(getTemperatureValue(res));
      setModifiedTopPValue(getTopPValue(res));
      setModifiedKValue(getKValue(res));
      setPrompts(getPrompts(res?.fineTuningDefaultValues?.prompt) || []);
      setAdditionalPrompts(res?.fineTuningConfiguredValues?.prompt || '');
      setRefetch(false);
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId) && refetch,
  });

  const {
    isLoading: isConfigureLLMFineTuningLoading,
    mutate: configureLLMFineTuning,
  } = useMutation(
    () => {
      return configureLLMFineTuningData({
        factoryId: rxjsState.plantId,
        top_p: modifiedTopPValue,
        k: modifiedKValue,
        temperature: modifiedTemperatureValue,
        prompt: additionalPrompts,
      });
    },
    {
      onSuccess: async () => {
        setRefetch(true);
      },
    },
  );

  useEffect(() => {
    if (isAccordianEnable === false) {
      setIsOpen(false);
    } else setIsOpen(true);
  }, [isAccordianEnable]);

  const handleAdditionalPromptsChange = (event) => {
    setAdditionalPrompts(event.target.value);
  };

  const handleAdditionalPromptsBlur = () => {
    setAdditionalPrompts(additionalPrompts.trim());
  };

  const onReset = () => {
    setModifiedKValue(config?.fineTuningDefaultValues?.k);
    setModifiedTemperatureValue(config?.fineTuningDefaultValues?.temperature);
    setModifiedTopPValue(config?.fineTuningDefaultValues?.top_p);
    setAdditionalPrompts('');
  };

  const handleClick = () => {
    if (isAccordianEnable) {
      setIsOpen(!isOpen);
    }
  };

  const handleSubmit = () => {
    setIsSubmitConfirmationDialogOpen(false);
    configureLLMFineTuning();
  };

  const handleReset = () => {
    setIsResetConfirmationDialogOpen(false);
    onReset();
  };

  return (
    <Accordion
      defaultExpanded={isAccordianEnable}
      disabled={!isAccordianEnable}
      expanded={isOpen}
    >
      <AccordionSummary
        data-testid="genci-enable-disable-container"
        onClick={handleClick}
      >
        {MESSAGE_STRINGS.LLM_FINE_TUNING}
      </AccordionSummary>
      <AccordionDetails data-testid="genci-llm-fine-tuning-details">
        {isLLMFineTuningDataLoading || isConfigureLLMFineTuningLoading ? (
          <StyledLoadingContainer>
            <LoadingIndicator />
          </StyledLoadingContainer>
        ) : (
          <>
            <StyledBox>{MESSAGE_STRINGS.PARAMETERS}</StyledBox>
            <StyledFlexBox>
              <StyledTypography>
                <StyledTitlesParams>
                  {MESSAGE_STRINGS.TEMPERATURE}
                </StyledTitlesParams>
                <GeneralTooltip
                  maxWidth="23.438rem"
                  message={
                    <StyledTypographyTooltip>
                      {MESSAGE_STRINGS.TEMPERATURE_TOOLTIP}
                    </StyledTypographyTooltip>
                  }
                  placement="top-start"
                >
                  <StyledInfoIconContainer>
                    <StyledInfoIcon />
                  </StyledInfoIconContainer>
                </GeneralTooltip>
              </StyledTypography>
              <StyledTypography>
                <StyledTitlesParams>{MESSAGE_STRINGS.TOP_P}</StyledTitlesParams>
                <GeneralTooltip
                  maxWidth="23.438rem"
                  message={
                    <StyledTypographyTooltip>
                      {MESSAGE_STRINGS.TOP_P_TOOLTIP}
                    </StyledTypographyTooltip>
                  }
                  placement="top-start"
                >
                  <StyledInfoIconContainer>
                    <StyledInfoIcon />
                  </StyledInfoIconContainer>
                </GeneralTooltip>
              </StyledTypography>
              <StyledTypography>
                <StyledTitlesParams>K</StyledTitlesParams>
                <GeneralTooltip
                  maxWidth="23.438rem"
                  message={
                    <StyledTypographyTooltip>
                      {MESSAGE_STRINGS.K_TOOLTIP}
                    </StyledTypographyTooltip>
                  }
                  placement="top-start"
                >
                  <StyledInfoIconContainer>
                    <StyledInfoIcon />
                  </StyledInfoIconContainer>
                </GeneralTooltip>
              </StyledTypography>
            </StyledFlexBox>
            <StyledSliderBox>
              <SMFSlider
                defaultValue={currentTemperatureValue}
                minValue={0}
                maxValue={2}
                value={modifiedTemperatureValue}
                setValue={setModifiedTemperatureValue}
              />
              <SMFSlider
                defaultValue={currentTopPValue}
                minValue={0}
                maxValue={1}
                value={modifiedTopPValue}
                setValue={setModifiedTopPValue}
              />
              <SMFSlider
                defaultValue={currentKValue}
                minValue={1}
                maxValue={10}
                value={modifiedKValue}
                setValue={setModifiedKValue}
                fixedTo={0}
                step={1}
              />
            </StyledSliderBox>
            <StyledFlexContainer>
              <StyledDivDefault>
                <StyledDefaultTitle>
                  {MESSAGE_STRINGS.DEFAULT_PROMPT_RULES}
                </StyledDefaultTitle>
                <div style={{ fontSize: '14px' }}>
                  {prompts?.map((prompt, index) => (
                    <StyledParagraph key={prompt}>
                      {index + 1}. {prompt.trim()}
                    </StyledParagraph>
                  ))}
                </div>
              </StyledDivDefault>
              <StyledDivAdditional>
                <StyledAdditionalTitle>
                  {MESSAGE_STRINGS.ADDITIONAL_PROMPT_RULES}
                </StyledAdditionalTitle>
                <Textarea
                  fullWidth
                  multiline
                  placeholder={MESSAGE_STRINGS.TEXTAREA_PLACEHOLDER}
                  value={additionalPrompts}
                  onChange={handleAdditionalPromptsChange}
                  onBlur={handleAdditionalPromptsBlur}
                  data-testid="additional-prompts-textarea"
                  inputProps={{ maxLength: 1000 }}
                />
              </StyledDivAdditional>
            </StyledFlexContainer>
            <StyledButtonContainer>
              <StyledResetButton
                onClick={() => setIsResetConfirmationDialogOpen(true)}
              >
                Reset
              </StyledResetButton>
              <StyledSubmitButton
                onClick={() => setIsSubmitConfirmationDialogOpen(true)}
              >
                Save
              </StyledSubmitButton>
            </StyledButtonContainer>
          </>
        )}
      </AccordionDetails>
      <GeneralDialog open={isSubmitConfirmationDialogOpen} fullWidth={false}>
        <ModalBody
          maxWidth="30rem"
          maxHeight="15.438rem"
          dialogTitle={
            <ModalTitleWrapper>
              <ExclamationIcon height="2rem" width="2rem" />
              <ConfirmTypography>{MESSAGE_STRINGS.CONFIRM}</ConfirmTypography>
            </ModalTitleWrapper>
          }
          dialogContent={
            <Box sx={{ paddingLeft: '2.75rem', paddingBottom: '1rem' }}>
              <ModalContentText>
                {MESSAGE_STRINGS['LLM.fineTuning.parameter.change']}
              </ModalContentText>
              <ModalConfirmationText>
                {MESSAGE_STRINGS['LLM.fineTuning.save.change']}
              </ModalConfirmationText>
            </Box>
          }
          actions={
            <Actions
              confirmText={MESSAGE_STRINGS.YES}
              cancelText={MESSAGE_STRINGS.NO}
              handleSave={handleSubmit}
              handleClose={() => setIsSubmitConfirmationDialogOpen(false)}
            />
          }
        />
      </GeneralDialog>
      <GeneralDialog open={isResetConfirmationDialogOpen} fullWidth={false}>
        <ModalBody
          maxWidth="30rem"
          dialogTitle={
            <ModalTitleWrapper>
              <ExclamationIcon height="2rem" width="2rem" />
              <ConfirmTypography>{MESSAGE_STRINGS.CONFIRM}</ConfirmTypography>
            </ModalTitleWrapper>
          }
          dialogContent={
            <Box sx={{ paddingLeft: '2.75rem', paddingBottom: '1rem' }}>
              <ModalConfirmationText>
                {MESSAGE_STRINGS['LLM.fineTuning.reset']}
              </ModalConfirmationText>
            </Box>
          }
          actions={
            <Actions
              confirmText={MESSAGE_STRINGS.YES}
              cancelText={MESSAGE_STRINGS.NO}
              handleSave={handleReset}
              handleClose={() => setIsResetConfirmationDialogOpen(false)}
            />
          }
        />
      </GeneralDialog>
    </Accordion>
  );
};

LLMConfig.propTypes = {
  isAccordianEnable: PropTypes.bool,
};

export default LLMConfig;
