import { useState } from 'react';
import { MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import InfoIcon from '../../assets/img/infoIcon.svg';
import {
  DATA_SOURCES,
  QUERY_CONSTANTS,
  LOADING_TEXT,
  TEST_IDS,
} from '../../constants';
import MESSAGE_STRINGS from '../../constants/en-us';
import { getGenCIDataSources } from '../../utils/apiHelpers';
import { useRxjsState } from '../../hooks/useRxjsState';
import {
  DataSourceContainerBox,
  DataSourceContainerLabel,
  InfoIconWrapper,
  StyleIconButton,
  StyledTooltip,
} from './style';
import SMFSelect from '../../components/SMFSelect';
import DataSourceChangeModal from './DataSourceChangeModal';
import { resChangeOnDocumentUploadFeatureFlag } from '../../utils/helpers';

function DataSourceContainer({
  selectedDataSource,
  onSelectedDataSourceChanged,
  isAnyDocumentUploaded,
  isDMSConfigured,
  splitTreatments,
}) {
  const { rxjsState } = useRxjsState();
  const [availableGenCIDataSources, setAvailableGenCIDataSources] = useState(
    [],
  );
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [dataSourceToBeSelected, setDataSourceToBeSelected] = useState('');

  const handleDataSourceDropdownChange = (value) => {
    if (
      (value === DATA_SOURCES.DMS && isAnyDocumentUploaded) ||
      (value === DATA_SOURCES.DOC_UPLOAD && isDMSConfigured)
    ) {
      setShowConfirmDialog(true);
      setDataSourceToBeSelected(value);
    } else {
      onSelectedDataSourceChanged(value);
    }
  };

  const handleConfirmDialog = () => {
    onSelectedDataSourceChanged(dataSourceToBeSelected);
    setShowConfirmDialog(false);
  };

  /* 
    Fetching GenCI Data Sources
  */
  const { isLoading: isGenCIDataSourcesLoading } = useQuery({
    queryKey: [QUERY_CONSTANTS.FETCH_GENCI_DATA_SOURCES],
    queryFn: () => {
      const queryParams = {
        factoryId: rxjsState.plantId,
      };
      return getGenCIDataSources(queryParams);
    },
    onSuccess: (res) => {
      const modifiedRes = resChangeOnDocumentUploadFeatureFlag(
        res,
        splitTreatments,
      );
      onSelectedDataSourceChanged(
        modifiedRes?.selectedDataSource || DATA_SOURCES.NONE,
      );
      setAvailableGenCIDataSources(modifiedRes?.dataSources);
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId),
  });

  return (
    <DataSourceContainerBox>
      <DataSourceContainerLabel>
        {MESSAGE_STRINGS.DATA_SOURCE_CONTAINER_LABEL}
      </DataSourceContainerLabel>
      <InfoIconWrapper>
        <StyledTooltip
          title={MESSAGE_STRINGS.DATA_SOURCE_TOOLTIP}
          data-testid={TEST_IDS.DATA_SOURCE_TOOLTIP}
          placement="right-end"
        >
          <StyleIconButton>
            <InfoIcon
              height="1rem"
              width="1rem"
              data-testid={TEST_IDS.DATA_SOURCE_TOOLTIP_ICON}
            />
          </StyleIconButton>
        </StyledTooltip>
      </InfoIconWrapper>
      <SMFSelect
        value={selectedDataSource}
        onChange={(e) => handleDataSourceDropdownChange(e.target.value)}
        width={272}
      >
        {!isGenCIDataSourcesLoading &&
          availableGenCIDataSources.map((dataSource) => (
            <MenuItem key={dataSource} value={dataSource}>
              {dataSource}
            </MenuItem>
          ))}
        {isGenCIDataSourcesLoading && (
          <MenuItem key={LOADING_TEXT} value={LOADING_TEXT} disabled>
            {LOADING_TEXT}
          </MenuItem>
        )}
      </SMFSelect>
      {showConfirmDialog && (
        <DataSourceChangeModal
          open={showConfirmDialog}
          onConfirm={handleConfirmDialog}
          onCancel={() => setShowConfirmDialog(false)}
        />
      )}
    </DataSourceContainerBox>
  );
}

DataSourceContainer.propTypes = {
  selectedDataSource: PropTypes.string,
  onSelectedDataSourceChanged: PropTypes.func,
  isAnyDocumentUploaded: PropTypes.bool,
  isDMSConfigured: PropTypes.bool,
  splitTreatments: PropTypes.instanceOf(Object),
};

export default DataSourceContainer;
