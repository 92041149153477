const common = {
  black: '#000',
  white: '#fff',
  tooltip: {
    background: '#616161E5',
  },
};

const threshold = {
  green: '#43B02A',
  red: '#DA291C',
  amber: '#ED8B00',
};

export { common, threshold };
