import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import GeneralDialog from '../../../components/GeneralDialog';
import ModalBody from '../../../components/GeneralDialog/GeneralDialogBody';
import MESSAGE_STRINGS from '../../../constants/en-us';
import Actions from '../../../components/ModalActions';
import ConfirmIcon from '../../../assets/img/confirmIcon.svg';
import {
  ConfirmTypography,
  ModalConfirmationText,
  ModalContentText,
  ModalTitleWrapper,
} from './style';

const DataSourceChangeModal = ({ open, onCancel, onConfirm }) => {
  return (
    <GeneralDialog
      open={open}
      fullWidth={false}
      sx={{
        '& .dialog-title': { fontWeight: '700' },
      }}
    >
      <ModalBody
        maxWidth="30rem"
        dialogTitle={
          <ModalTitleWrapper>
            <ConfirmIcon height="2rem" width="2rem" />
            <ConfirmTypography>{MESSAGE_STRINGS.CONFIRM}</ConfirmTypography>
          </ModalTitleWrapper>
        }
        dialogContent={
          <Box sx={{ paddingLeft: '2.75rem' }}>
            <ModalContentText>
              {MESSAGE_STRINGS['DMS.dataSource.modal.content']}
            </ModalContentText>
            <ModalConfirmationText>
              {MESSAGE_STRINGS['DMS.modal.continue']}
            </ModalConfirmationText>
          </Box>
        }
        actions={
          <Actions
            confirmText={MESSAGE_STRINGS.YES}
            cancelText={MESSAGE_STRINGS.NO}
            handleSave={onConfirm}
            handleClose={onCancel}
          />
        }
      />
    </GeneralDialog>
  );
};

DataSourceChangeModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DataSourceChangeModal;
