import React from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import { HIERARCHY_LEVEL } from '../../constants/index';
import CustomTreeItemWithIcon from '../CustomTreeItemWithIcon';
import CustomTreeItem from './style.js';

const renderTreeNodes = (nodes, handleHierarchy) => {
  return nodes.map((node) =>
    node.entityType !== HIERARCHY_LEVEL.ASSET ? (
      <CustomTreeItem
        key={node?.entityId}
        nodeId={`${node?.entityId}`}
        label={`${node?.entityNumber} - ${node?.entityName}`}
        style={{ fontSize: '0.3rem' }}
        data-testid="genci-tree-item"
        onClick={() => handleHierarchy('')}
      >
        {node?.entityChildren &&
          renderTreeNodes(node?.entityChildren, handleHierarchy)}
      </CustomTreeItem>
    ) : (
      <CustomTreeItemWithIcon
        key={node?.entityId}
        nodeId={`${node?.entityId}`}
        labelText={`${node?.entityNumber} - ${node?.entityName}`}
        style={{ fontSize: '0.3rem' }}
        onClick={() => handleHierarchy(node)}
        node={node}
        handleHierarchy={handleHierarchy}
        data-testid="genci-tree-item-with-icon"
      >
        {node?.entityChildren &&
          renderTreeNodes(node?.entityChildren, handleHierarchy)}
      </CustomTreeItemWithIcon>
    ),
  );
};

function RenderTreeData({ data, handleHierarchy }) {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      data-testid="genci-tree-container"
    >
      {renderTreeNodes([data], handleHierarchy)}
    </TreeView>
  );
}

RenderTreeData.propTypes = {
  data: PropTypes.shape({
    entityId: PropTypes.number,
    entityNumber: PropTypes.string,
    entityType: PropTypes.string,
    parentEntityId: PropTypes.number,
    entityName: PropTypes.string,
    entityHierarchy: PropTypes.string,
    hierarchyLevel: PropTypes.number,
    entityChildren: PropTypes.instanceOf(Array),
    entityAutoId: PropTypes.number,
    fileStatus: PropTypes.string,
  }),
  handleHierarchy: PropTypes.func,
};

export default RenderTreeData;
