import { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment-timezone';
import { config, globalstate$ } from '@smf/ui-util-app';
import { useRxjsState } from '../../hooks/useRxjsState';
import Accordion from '../../components/SMFAccordion';
import InfoIcon from '../../assets/img/infoIcon.svg';
import MESSAGE_STRINGS, { ACCORDION_STATUS } from '../../constants/en-us';
import AccordionStatus from '../../components/AccordionStatus';
import * as S from './style';
import Button from '../../components/Button';
import SMFSelect from '../../components/SMFSelect';
import ExternalLink from '../../assets/img/ExternalLink.svg';
import RegenerateIcon from '../../assets/img/RegenerateIcon.svg';
import RegenerateIconBlack from '../../assets/img/RegenerateIconBlack.svg';
import EditIcon from '../../assets/img/EditIcon.svg';
import ExclamationIcon from '../../assets/img/ExclamationIcon.svg';
import {
  getDmsFolders,
  getPlantTimezone,
  saveDmsFolders,
} from '../../utils/apiHelpers';
import GeneralDialog from '../../components/GeneralDialog';
import ModalBody from '../../components/GeneralDialog/GeneralDialogBody';
import Actions from '../../components/ModalActions';
import { useToastContext } from '../../context/toastContext';
import {
  DATA_SOURCES,
  DMS_WS,
  DMS_WS_ACTION,
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import Loader from '../../components/Loader';
import EllipsisTooltip from '../../components/EllipsisTooltip';
import LoadingIndicator from '../../components/LoadingIndicator';

function DMSConfig({ enableAccordian, setIsDMSConfigured }) {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const { rxjsState } = useRxjsState();
  const [isSyncBtnHovered, setIsSyncBtnHovered] = useState(false);
  const [selectedFolderPath, setSelectedFolderPath] = useState('');
  const [selectedRelativePath, setSelectedRelativePath] = useState('');
  const [configStatus, setConfigStatus] = useState(false);
  const [embeddingStatus, setEmbeddingStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED,
  );
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [defaultSelectedPath, setDefaultSelectedPath] = useState('');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isCancelBtnVisible, setIsCancelBtnVisible] = useState(false);
  const [showOnEditModal, setShowOnEditModal] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [isExternalLinkVisible, setIsExternalLinkVisible] = useState(false);
  const { toastDispatch } = useToastContext();
  const [lastSyncTimeStamp, setLastSyncTimeStamp] = useState('');
  const [plantLocation, setPlantLocation] = useState('');
  const webSocketRef = useRef(null);

  const creatingDmsEmbedding = embeddingStatus === ACCORDION_STATUS.IN_PROGRESS;
  const { plantId } = rxjsState;
  const emailId = rxjsState?.userData?.email;

  const { data: foldersData, isLoading: isFolderApiLoading } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_DMS_FOLDERS],
    queryFn: () => getDmsFolders(rxjsState?.plantId),
    onSuccess: (response) => {
      setDefaultSelectedPath(response?.selectedFolderPath);
      setSelectedFolderPath(response?.selectedFolderPath);
      const updatedRelPath = response?.selectedRelativePath
        ?.replace(/%20/g, ' ')
        .replace(/\//g, ' / ');
      setSelectedRelativePath(updatedRelPath);
      setIsExternalLinkVisible(
        response?.selectedFolderPath &&
          (response?.dmsConfigStatus === ACCORDION_STATUS.COMPLETED ||
            response?.dmsConfigStatus === ACCORDION_STATUS.IN_PROGRESS),
      );
      setConfigStatus(response?.dmsConfigStatus);
      setEmbeddingStatus(response?.embeddingStatus);
      setLastSyncTimeStamp(response?.timestamp);
      setIsDMSConfigured(
        response?.dmsConfigStatus === ACCORDION_STATUS.COMPLETED,
      );
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: rxjsState?.plantId !== undefined,
  });

  const { isLoading: isTimeZoneLoading } = useQuery({
    queryKey: [QUERY_CONSTANTS.GET_PLANT_TIMEZONE],
    queryFn: () =>
      getPlantTimezone({
        factoryID: rxjsState?.plantId,
      }),
    onSuccess: (response) => {
      setPlantLocation(response?.plantLocation);
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: rxjsState?.plantId !== undefined,
  });

  const { mutate: saveFolders, isLoading: isSaveFoldersExecuting } =
    useMutation(
      [QUERY_CONSTANTS.SAVE_DMS_FOLDERS],
      (data) => saveDmsFolders(data),
      {
        onSuccess: (response) => {
          if (response?.selectedPath)
            toastDispatch({
              type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
              payload: {
                message: MESSAGE_STRINGS.TOAST_DMS_SAVE_MESSAGE,
              },
            });
          setDefaultSelectedPath(response?.selectedPath);
          setSelectedFolderPath(response?.selectedPath);
          const updatedRelPath = response?.selectedRelativePath
            ?.replace(/%20/g, ' ')
            .replace(/\//g, ' / ');
          setSelectedRelativePath(updatedRelPath);
          setIsExternalLinkVisible(
            response?.selectedPath &&
              (response?.dmsConfigStatus === ACCORDION_STATUS.COMPLETED ||
                response?.dmsConfigStatus === ACCORDION_STATUS.IN_PROGRESS),
          );
          setConfigStatus(response?.dmsConfigStatus);
          setEmbeddingStatus(ACCORDION_STATUS.NOT_STARTED);
          setIsCancelBtnVisible(false);
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: rxjsState?.plantId !== undefined,
      },
    );

  const handleFolderChange = (event) => {
    setSelectedFolderPath(event.target.value);
    setIsSaveEnabled(true);
    setIsCancelBtnVisible(true);
    setConfigStatus(ACCORDION_STATUS.IN_PROGRESS);
  };

  const handleEdit = () => {
    setConfigStatus(ACCORDION_STATUS.IN_PROGRESS);
    setIsCancelBtnVisible(true);
    setIsEditting(true);
    setShowOnEditModal(true);
  };

  const handleSave = () => {
    if (!showOnEditModal) {
      saveFolders({
        factoryId: `${rxjsState?.plantId}`,
        selectedPath: selectedFolderPath,
        dataSource: DATA_SOURCES.DMS,
      });
      setIsSaveEnabled(false);
    } else {
      setOpenSaveModal(true);
    }
  };

  const handleSaveContinue = () => {
    const authToken = globalstate$?.value?.sessionToken;
    const socketAuthorizationTokens = `${authToken.replace('Bearer ', '')}`;
    webSocketRef.current.send(
      JSON.stringify({
        action: DMS_WS_ACTION,
        authorizationToken: socketAuthorizationTokens,
        isManualSync: true,
        cancelRequest: false,
        isDelete: true,
        factoryId: `${rxjsState?.plantId}`,
      }),
    );
    saveFolders({
      factoryId: `${rxjsState?.plantId}`,
      selectedPath: selectedFolderPath,
      dataSource: DATA_SOURCES.DMS,
    });
    setOpenSaveModal(false);
    setIsEditting(false);
    setIsSaveEnabled(false);
  };

  const handleSaveDiscard = () => {
    setOpenSaveModal(false);
  };

  const handleCancel = () => {
    setOpenCancelModal(true);
  };

  const handleCancelDiscard = async () => {
    setOpenCancelModal(false);
    setIsEditting(false);
    setIsSaveEnabled(false);
    setIsCancelBtnVisible(false);
    setSelectedFolderPath(defaultSelectedPath);
    setConfigStatus(embeddingStatus);
  };
  const handleCancelContinue = () => {
    setOpenCancelModal(false);
  };

  const handleSyncBtnClick = () => {
    setEmbeddingStatus(ACCORDION_STATUS.IN_PROGRESS);
    const authToken = globalstate$?.value?.sessionToken;
    const socketAuthorizationTokens = `${authToken.replace('Bearer ', '')}`;
    webSocketRef.current.send(
      JSON.stringify({
        action: DMS_WS_ACTION,
        authorizationToken: socketAuthorizationTokens,
        isManualSync: true,
        cancelRequest: false,
        factoryId: `${rxjsState?.plantId}`,
      }),
    );
  };

  const handleCancelEmbedding = () => {
    setIsSyncBtnHovered(false);
    const authToken = globalstate$?.value?.sessionToken;
    const socketAuthorizationTokens = `${authToken.replace('Bearer ', '')}`;
    webSocketRef.current.send(
      JSON.stringify({
        action: DMS_WS_ACTION,
        authorizationToken: socketAuthorizationTokens,
        isManualSync: true,
        cancelRequest: true,
        factoryId: `${rxjsState?.plantId}`,
      }),
    );
  };

  const showBackdropLoader = isFolderApiLoading || isSaveFoldersExecuting;

  const cleanupWebSocket = () => {
    // eslint-disable-next-line no-console
    console.debug('Cleaning up DMS ws connection');
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
  };

  useEffect(() => {
    const handleWebSokcetEventMessage = (eventData) => {
      if (eventData?.isError) {
        setEmbeddingStatus(configStatus);
        setIsSyncBtnHovered(false);
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
          },
        });
      }
      if (eventData?.isCancelResponse) {
        setEmbeddingStatus(eventData?.embeddingStatus);
        setLastSyncTimeStamp(eventData?.timestamp);
        return;
      }
      if (eventData?.embeddingStatus === ACCORDION_STATUS.COMPLETED) {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_EMBEDDING_CREATED_MESSAGE,
          },
        });
        setConfigStatus(ACCORDION_STATUS.COMPLETED);
        setEmbeddingStatus(ACCORDION_STATUS.COMPLETED);
        setLastSyncTimeStamp(eventData?.timestamp);
        setIsSyncBtnHovered(false);
        setIsDMSConfigured(true);
      }
    };
    let retryCount = 0;
    const { maxRetries, retryInterval } = DMS_WS;
    const initializeWebSocket = (url, authToken) => {
      webSocketRef.current = new WebSocket(
        url,
        authToken?.replace('Bearer ', ''),
      );
      webSocketRef.current.onopen = (event) =>
        // eslint-disable-next-line no-console
        console.debug('DMS ws connected at ', event.timeStamp);
      webSocketRef.current.onclose = (event) => {
        // eslint-disable-next-line no-console
        console.debug('DMS ws disconnected due to ', event, event.reason);
        if (event.code === DMS_WS.WS_DISCONNECTION_CODE) {
          // Connection closed unexpectedly
          if (retryCount < maxRetries) {
            // Attempt to reconnect with backoff
            const retryDelay = 2 ** retryCount * retryInterval;
            console.debug(`Retrying connection in ${retryDelay} ms`);
            setTimeout(() => initializeWebSocket(url, authToken), retryDelay);
            retryCount += 1;
          } else {
            console.error(
              'Exceeded maximum retry attempts. Connection failed.',
            );
          }
        }
      };
      webSocketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        handleWebSokcetEventMessage(message);
      };
      webSocketRef.current.onError = () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: {
            message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
          },
        });
      };
    };
    const authToken = globalstate$?.value?.sessionToken;
    if (plantId && emailId) {
      const WSS_ENDPOINT_DMS_DATA = `wss://${config.BASE_WS_API_URL}/genciDms?plantId=${plantId}&emailId=${emailId}&source=genciDms`;
      initializeWebSocket(WSS_ENDPOINT_DMS_DATA, authToken);
    }
    // wait for 2 seconds before sending message

    return () => cleanupWebSocket();
  }, [toastDispatch, plantId, emailId]);

  return (
    <Accordion defaultExpanded={enableAccordian} disabled={!enableAccordian}>
      <AccordionSummary>
        <S.FlexBox>
          <S.AccordionSummaryContainer>
            {MESSAGE_STRINGS.DMS_CONFIG}
            <S.StyledTooltip
              title={MESSAGE_STRINGS.DMS_CONFIG_TOOLTIP}
              placement="right-end"
            >
              <S.StyleIconButton>
                <InfoIcon height="1rem" width="1rem" />
              </S.StyleIconButton>
            </S.StyledTooltip>
          </S.AccordionSummaryContainer>
          <AccordionStatus
            status={creatingDmsEmbedding ? embeddingStatus : configStatus}
          />
        </S.FlexBox>
      </AccordionSummary>

      <AccordionDetails>
        <S.ConfigRow>
          <S.InnerFlexContainer>
            {MESSAGE_STRINGS.FOLDER_PATH}
            <S.StyledAccordionDetailBox>
              {!isExternalLinkVisible || isEditting ? (
                <SMFSelect
                  value={selectedFolderPath || MESSAGE_STRINGS.SELECT}
                  width="25rem"
                  onChange={handleFolderChange}
                >
                  {foldersData?.folderPaths?.map((folder) => {
                    return (
                      <MenuItem
                        sx={{ maxWidth: '25rem' }}
                        value={folder.path}
                        key={folder.path}
                      >
                        <S.StyledTooltip
                          title={folder.path}
                          placement="right-end"
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {folder.path}
                          </div>
                        </S.StyledTooltip>
                      </MenuItem>
                    );
                  })}
                </SMFSelect>
              ) : (
                <>
                  <S.StyledLink
                    href={selectedFolderPath}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <S.ExternalLinkWrapper>
                      <EllipsisTooltip text={selectedRelativePath} />
                      <ExternalLink height="0.75rem" width="0.75rem" />
                    </S.ExternalLinkWrapper>
                  </S.StyledLink>

                  {creatingDmsEmbedding ? (
                    <S.LoadingIconBox>
                      <LoadingIndicator open={false} size="1rem" />{' '}
                    </S.LoadingIconBox>
                  ) : (
                    <S.EditIconBox
                      onClick={handleEdit}
                      data-testid={TEST_IDS.EDIT_BUTTON}
                    >
                      <S.StyledTooltip
                        title={MESSAGE_STRINGS.DMS_CONFIG_EDIT_ICON_TOOLTIP}
                        placement="right-end"
                      >
                        <S.StyleIconButton>
                          <EditIcon height="1.5rem" width="1.5rem" />
                        </S.StyleIconButton>
                      </S.StyledTooltip>
                    </S.EditIconBox>
                  )}

                  {!creatingDmsEmbedding &&
                    (configStatus !== ACCORDION_STATUS.COMPLETED ? (
                      <S.SyncButton
                        data-testid={TEST_IDS.SYNC_BUTTON}
                        onMouseEnter={() => setIsSyncBtnHovered(true)}
                        onMouseLeave={() => {
                          setIsSyncBtnHovered(false);
                        }}
                        onClick={handleSyncBtnClick}
                      >
                        {isSyncBtnHovered ? (
                          <RegenerateIconBlack />
                        ) : (
                          <RegenerateIcon />
                        )}
                        <S.SyncTypography>
                          {MESSAGE_STRINGS.SYNC}
                        </S.SyncTypography>
                      </S.SyncButton>
                    ) : (
                      <S.StyledTooltip
                        title={
                          MESSAGE_STRINGS.DMS_CONFIG_DISABLED_GENERATE_EMBEDDINGS_TOOLTIP
                        }
                        placement="top"
                      >
                        <span>
                          <S.DisabledSyncButton
                            data-testid={TEST_IDS.DISABLED_SYNC_BUTTON}
                          >
                            <RegenerateIcon />
                            <S.SyncTypography>
                              {MESSAGE_STRINGS.SYNC}
                            </S.SyncTypography>
                          </S.DisabledSyncButton>
                        </span>
                      </S.StyledTooltip>
                    ))}
                  {configStatus === ACCORDION_STATUS.COMPLETED &&
                    !creatingDmsEmbedding && (
                      <>
                        <S.SyncTimeTypography>
                          {MESSAGE_STRINGS.LAST_SYNC}
                        </S.SyncTimeTypography>
                        {!isTimeZoneLoading && plantLocation && (
                          <Typography>
                            {moment(lastSyncTimeStamp)
                              .tz(plantLocation)
                              .format('MM/DD/YYYY HH:mm z')}
                          </Typography>
                        )}
                      </>
                    )}
                  {creatingDmsEmbedding && (
                    <>
                      <S.SyncMsgTypography>
                        {MESSAGE_STRINGS.DMS_EMBEDDINGS}
                      </S.SyncMsgTypography>
                      <S.CancelSyncButton
                        data-testid={TEST_IDS.CANCEL_SYNC_BUTTON}
                        onClick={handleCancelEmbedding}
                      >
                        <S.CancelTypography>
                          {MESSAGE_STRINGS.CANCEL}
                        </S.CancelTypography>
                      </S.CancelSyncButton>
                    </>
                  )}
                </>
              )}
            </S.StyledAccordionDetailBox>
          </S.InnerFlexContainer>
        </S.ConfigRow>
        <Box>
          <S.SaveCancelWrapper>
            <S.SaveButtonContainer data-testid="cancelButton">
              {isCancelBtnVisible && (
                <Button buttonType="secondary" onClick={handleCancel}>
                  <S.ButtonTypography>
                    {MESSAGE_STRINGS.CANCEL}
                  </S.ButtonTypography>
                </Button>
              )}
            </S.SaveButtonContainer>
            <S.SaveButtonContainer data-testid="saveButton">
              <Button disabled={!isSaveEnabled} onClick={handleSave}>
                <S.ButtonTypography>{MESSAGE_STRINGS.SAVE}</S.ButtonTypography>
              </Button>
            </S.SaveButtonContainer>
          </S.SaveCancelWrapper>
        </Box>
        <GeneralDialog open={openSaveModal} fullWidth={false}>
          <ModalBody
            maxWidth="30rem"
            dialogTitle={
              <S.ModalTitleWrapper>
                <ExclamationIcon height="2rem" width="2rem" />
                <S.ConfirmTypography>
                  {MESSAGE_STRINGS.CONFIRM}
                </S.ConfirmTypography>
              </S.ModalTitleWrapper>
            }
            dialogContent={
              <Box sx={{ paddingLeft: '2.75rem' }}>
                <S.ModalContentText>
                  {MESSAGE_STRINGS['DMS.saveChanges.content']}
                </S.ModalContentText>
                <S.ModalConfirmationText>
                  {MESSAGE_STRINGS['DMS.modal.continue']}
                </S.ModalConfirmationText>
              </Box>
            }
            actions={
              <Actions
                confirmText={MESSAGE_STRINGS.YES}
                cancelText={MESSAGE_STRINGS.NO}
                handleSave={handleSaveContinue}
                handleClose={handleSaveDiscard}
                isSaveDisabled={false}
              />
            }
          />
        </GeneralDialog>
        <GeneralDialog open={openCancelModal} fullWidth={false}>
          <ModalBody
            maxWidth="30rem"
            minHeight="11.25rem"
            dialogTitle={
              <S.ModalTitleWrapper>
                <ExclamationIcon height="2rem" width="2rem" />
                <S.ConfirmTypography>
                  {MESSAGE_STRINGS.CONFIRM}
                </S.ConfirmTypography>
              </S.ModalTitleWrapper>
            }
            dialogContent={
              <Box sx={{ paddingLeft: '2.75rem' }}>
                <S.ModalContentText>
                  {MESSAGE_STRINGS['DMS.cancelChanges.content']}
                </S.ModalContentText>
                <S.ModalConfirmationText>
                  {MESSAGE_STRINGS['DMS.modal.continue']}
                </S.ModalConfirmationText>
              </Box>
            }
            actions={
              <Actions
                confirmText={MESSAGE_STRINGS.DISCARD}
                cancelText={MESSAGE_STRINGS.CONTINUE}
                handleSave={handleCancelDiscard}
                handleClose={handleCancelContinue}
                isSaveDisabled={false}
              />
            }
          />
        </GeneralDialog>
        <S.BackdropContainer data-testid="backdrop">
          <S.CustomBackdrop open={showBackdropLoader}>
            <Loader />
          </S.CustomBackdrop>
        </S.BackdropContainer>
      </AccordionDetails>
    </Accordion>
  );
}

DMSConfig.propTypes = {
  enableAccordian: PropTypes.bool,
  setIsDMSConfigured: PropTypes.func,
};

export default DMSConfig;
