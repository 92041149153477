import { genciConfig, factoryGeneralConfig } from '@smf/ui-util-app';
/**
 ** API Helper functions goes here
 */
export async function updateGenCIStatus(data) {
  const result = genciConfig.updateGenCIStatus(data);
  return result;
}

export async function getSingleDbAutoPromptConfigStatus(params) {
  const result = genciConfig.getSingleDbAutoPromptConfigStatus(params);
  return result;
}

export async function getVirtualAssistantAutoPromptConfigStatus(params) {
  const result = genciConfig.getVirtualAssistantAutoPromptConfigStatus(params);
  return result;
}

export async function getEquipmentHierarchyData(params) {
  return genciConfig.getEquipmentHierarchy(params);
}

export async function getPreSignedUrlForGenCI(params) {
  return genciConfig.getPreSignedUrlForGenCI(params);
}

export async function uploadFileToS3(presignedUrl, file) {
  return genciConfig.uploadGenCIConfigFile(presignedUrl, file);
}

export async function updateFileConfigData(params) {
  return genciConfig.updateGenCIFileConfigInDB(params);
}

export async function getGenCIFileData(factoryId) {
  return genciConfig.getGenCIFileData(factoryId);
}

export async function getDmsFolders(factoryId) {
  return genciConfig.getDmsFolders(factoryId);
}

export async function saveDmsFolders(data) {
  return genciConfig.saveDmsFolders(data);
}

export async function getGenCIDataSources(params) {
  return genciConfig.getGenCIDataSources(params);
}

export async function getLLMFineTuningData(params) {
  return genciConfig.getLLMFineTuningData(params);
}

export async function configureLLMFineTuningData(data) {
  return genciConfig.configureLLMFineTuningData(data);
}

export async function getPlantTimezone(params) {
  return factoryGeneralConfig.getPlantTimezone(params);
}
