import { createTheme } from '@mui/material';
import { common, threshold } from './commonColors';
import customBreakpoints from './responsiveness';
import customTypography from './typography';

export const darkPalette = {
  primary: {
    main: '#0091CE',
    light: '#00A3E0',
    dark: '#0076A8',
    contrast: '#000000',
    background: {
      outlined: 'rgba(0, 163, 224, 0.5)',
      containedHover: '#005376',
      outlinedHover: 'rgba(0, 124, 176, 0.08)',
    },
  },
  secondary: {
    main: '#0D8390',
    light: '#0097A9',
    dark: '#007680',
    contrast: '#FFFFFF',
    background: {
      outlined: 'rgba(0, 151, 169, 0.5)',
      containedHover: '#00535A',
      outlinedHover: 'rgba(0, 151, 169, 0.08)',
    },
  },
  text: {
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: '#A7A8AA',
    disabled: 'rgba(255, 255, 255, 0.38)',
  },
  action: {
    active: 'rgba(255, 255, 255, 0.54)',
    hover: 'rgba(255, 255, 255, 0.04)',
    selected: 'rgba(255, 255, 255, 0.08)',
    disabled: 'rgba(255, 255, 255, 0.26)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    focus: 'rgba(255, 255, 255, 0.12)',
  },
  error: {
    main: '#F8473A',
    dark: '#C6392E ',
    light: '#FB9189',
    contrast: '#000000',
    background: {
      main: '#FEEDEB',
      containedHover: '#AE3229',
      outlinedHover: 'rgba(218, 41, 28, 0.08)',
    },
    border: {
      outlined: 'rgba(248, 71, 58, 0.5)',
    },
    content: '#631C17',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrast: '#000000',
    background: {
      main: '#E9F4FE',
      containedHover: '#1769AA',
      outlinedHover: 'rgba(33, 150, 243, 0.08)',
    },
    border: {
      outlined: 'rgba(33, 150, 243, 0.5)',
    },
    content: '#0D3C61',
  },
  warning: {
    main: '#ED8B00',
    dark: ' #BE6F00',
    light: '#F2AE4D',
    contrast: '#000000',
    background: {
      main: '#FDF3E5',
      containedHover: '#A66100',
      outlinedHover: 'rgba(237, 139, 0, 0.08)',
    },
    border: {
      outlined: 'rgba(237, 139, 0, 0.5)',
    },
    content: '#5F2B01',
  },
  success: {
    main: '#4CAF50',
    dark: '#5E841A',
    light: '#9EC951',
    contrast: '#000000',
    background: {
      main: '#F3F8E9',
      containedHover: '#5E841A',
      outlinedHover: 'rgba(134, 188, 37, 0.08)',
    },
    border: {
      outlined: 'rgba(134, 188, 37, 0.5)',
    },
    content: '#364B0F',
  },
  //    ? NOTE: other generic component styles
  generic: {
    divider: {
      fill: 'rgba(255, 255, 255, 0.12)',
    },
    backdrop: {
      overlay: 'rgba(255, 255, 255, 0.15)',
    },
    rating: {
      active: '#FFB400',
    },
    snackbar: {
      background: '#CCCCCC',
    },
    borderOutlined: 'rgba(255, 255, 255, 0.23)',
    borderStandardInputLine: 'rgba(255, 255, 255, 0.42)',
    toggleTrackBackground: 'rgba(255, 255, 255, 0.87)',
    hoverSelectedDropdownBackground: 'rgba(255, 255, 255, 0.04)',
  },
  background: {
    paper: '#000000',
    default: '#222222',
  },
  grey: {
    grey10: '#63666A',
    grey50: '#111111',
    grey100: '#222222',
    grey200: '#333333',
    grey300: '#444444',
    grey400: '#9E9E9E',
    grey500: '#BDBDBD',
    grey600: '#E0E0E0',
    grey700: '#EEEEEE',
    grey800: '#F5F5F5',
    grey900: '#FAFAFA',
    greyA100: '#303030',
    greyA200: '#616161',
    greyA400: '#AAAAAA',
    greyA700: '#D5D5D5',
  },
  typography: customTypography,
  elevations: {
    outlined: '0px 0px 0px 1px #E0E0E0',
    e1: '1px 1px 5px 2px rgba(255, 255, 255, 0.05), 0px 1px 1px rgba(255, 255, 255, 0.14), 0px 1px 3px rgba(255, 255, 255, 0.12)',
    e2: '1px 1px 5px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    e3: '1px 1px 5px 2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
    e4: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    e5: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)',
    e6: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    e7: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    e8: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    e9: '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    e10: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    e11: '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    e12: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    e13: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    e14: '0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    e15: '0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    e16: '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    e17: '0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    e18: '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    e19: '0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    e20: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    e21: '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    e22: '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    e23: '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    e24: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  },
  risk: {
    catastrophic: '#F8473A',
    major: '#ED8B00',
    moderate: '#FFCD00',
    minor: '#62B5E5',
    insignificant: '#C4C4C4',
  },
  threshold,
  charts: {
    unavailable: '#00000052',
    primary1: '#6FC2B4',
    secondary2: '#0076A8',
    target: '#9E9E9E',
  },
};

const darkTheme = createTheme({
  overrides: {},
  palette: darkPalette,
  common,
  typography: customTypography,
  breakpoints: customBreakpoints,
});

export default darkTheme;
