import { Box, Typography, styled } from '@mui/material';

export const ModalTitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.75rem',
  alignItems: 'center',
});

export const ModalContentText = styled(Typography)({
  fontWeight: 400,
  lineHeight: '1.19rem',
  fontSize: '0.875rem',
  marginTop: '1rem',
});

export const ConfirmTypography = styled(Typography)({
  fontWeight: 700,
});

export const ModalConfirmationText = styled(Typography)({
  fontWeight: 700,
  lineHeight: '1.19rem',
  fontSize: '0.875rem',
  marginTop: '1rem',
});
