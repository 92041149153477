import { useEffect, useState } from 'react';
import { globalstate$, FEATURE_FLAGS } from '@smf/ui-util-app';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import DataUploadAndMapping from '../DataUploadAndMapping/index.jsx';
import DisplaySettings from '../DisplaySettings/index.jsx';
import { useRxjsState } from '../../hooks/useRxjsState';
import DataSourceContainer from '../DataSourceContainer/index.jsx';
import { DATA_SOURCES } from '../../constants/index.js';
import { MainContainer, PageTitle, SettingsContainer } from './style.js';
import MESSAGE_STRINGS from '../../constants/en-us';
import DMSConfig from '../DMSConfig/index.jsx';
import LLMConfig from '../LLMConfig/index.jsx';

export default function ConfigContainer() {
  const { rxjsState } = useRxjsState();
  const [enableAccordian, setEnableAccordian] = useState(
    globalstate$.value?.configStatus?.factoryGeneral ?? false,
  );
  const [genCIStatus, setGenCIStatus] = useState(
    globalstate$.value?.configStatus?.genci ?? false,
  );
  useEffect(() => {
    setEnableAccordian(rxjsState?.configStatus?.factoryGeneral);
  }, [rxjsState?.configStatus?.factoryGeneral]);

  useEffect(() => {
    setGenCIStatus(rxjsState?.configStatus?.genci);
  }, [rxjsState?.configStatus?.genci]);

  const [selectedDataSource, setSelectedDataSource] = useState(
    DATA_SOURCES.NONE,
  );

  const { treatments: splitTreatments, isReady: splitIsReady } =
    useSplitTreatments({
      names: [FEATURE_FLAGS.GEN_CI?.DOCUMENT_UPLOAD],
    });

  const [isAnyDocumentUploaded, setIsAnyDocumentUploaded] = useState(false);
  const [isDMSConfigured, setIsDMSConfigured] = useState(false);

  const onSelectedDataSourceChanged = (dataSource) => {
    setSelectedDataSource(dataSource);
  };
  return (
    <MainContainer data-testid="app-main-container">
      <PageTitle variant="h3" data-testid="app-page-title">
        {MESSAGE_STRINGS.PAGE_TITLE_UI_SETTINGS}
      </PageTitle>
      <SettingsContainer>
        <DataSourceContainer
          selectedDataSource={selectedDataSource}
          onSelectedDataSourceChanged={onSelectedDataSourceChanged}
          isAnyDocumentUploaded={isAnyDocumentUploaded}
          isDMSConfigured={isDMSConfigured}
          splitIsReady={splitIsReady}
          splitTreatments={splitTreatments}
        />
        {selectedDataSource === DATA_SOURCES.DOC_UPLOAD && (
          <DataUploadAndMapping
            enableAccordian={enableAccordian}
            genCIStatus={genCIStatus}
            setIsAnyDocumentUploaded={setIsAnyDocumentUploaded}
          />
        )}
        {selectedDataSource === DATA_SOURCES.DMS && (
          <DMSConfig
            enableAccordian={enableAccordian}
            setIsDMSConfigured={setIsDMSConfigured}
          />
        )}
        <DisplaySettings enableAccordian={enableAccordian} />
        <LLMConfig isAccordianEnable={enableAccordian && genCIStatus} />
      </SettingsContainer>
    </MainContainer>
  );
}
