import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

const LabelTextContainer = styled(Typography)(() => ({
  fontWeight: 'inherit',
  flexGrow: 1,
}));

const LabelContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '1rem',
}));

export { LabelTextContainer, LabelContainer };
