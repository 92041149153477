import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { globalstate$ } from '@smf/ui-util-app';
import {
  getEquipmentHierarchyData,
  getGenCIFileData,
} from '../../utils/apiHelpers';
import {
  mergeHierarchyAndConfigFileData,
  getFileStatusToDisplay,
} from '../../utils/helpers';
import { useRxjsState } from '../../hooks/useRxjsState';
import DownArrow from '../../assets/img/downArrow.svg';
import Accordion from '../../components/SMFAccordion';
import RenderTreeData from '../../components/RenderTreeData';
import LoadingIndicator from '../../components/LoadingIndicator';
import GenCIUpload from '../GenCIUpload';
import { HIERARCHY_LEVEL } from '../../constants';
import {
  StyledTree,
  HierarchyTitle,
  TreeContainer,
  HeaderContainer,
  HeaderLabel,
  ValuesContainer,
  ValuesComponent,
  FileStatus,
  FileUploadedContainer,
  LodingIndicatorContainer,
  DataUploadAndMappingLabelContainer,
  GenCIUploadContainer,
  UploadAndFileStatusContainer,
} from './style';
import MESSAGE_STRINGS from '../../constants/en-us';

const DataUploadAndMapping = ({
  enableAccordian,
  genCIStatus,
  setIsAnyDocumentUploaded,
}) => {
  const { rxjsState } = useRxjsState();
  const { AccordionDetails, AccordionSummary } = Accordion;
  const [hierarchyData, setHierarchyData] = useState({});
  const [entityHierarchy, setEntityHierarchy] = useState('');
  const [entityName, setEntityName] = useState('');
  const [allFilesData, setAllFilesData] = useState([]);
  const [hierarchyFilesData, setHierarchyFilesData] = useState({});
  const [fileStatus, setFileStatus] = useState('');
  const [isExpanded, setIsExpanded] = useState(enableAccordian && genCIStatus);
  const { data: hierarchyAPIData, isLoading: isHierarchyDataLoading } =
    useQuery({
      queryKey: ['getEIHierarchy'],
      queryFn: () =>
        getEquipmentHierarchyData({
          plantId: rxjsState?.plantId,
          level: HIERARCHY_LEVEL.ASSET,
        }),
      onSuccess: (response) => {
        setHierarchyData(response?.data);
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: rxjsState?.plantId !== undefined,
    });

  const handleHierarchy = (entity) => {
    setEntityHierarchy(entity?.entityHierarchy);
    setEntityName(entity?.entityName);
  };

  const { data: allConfigFileAPIData, refetch: refetchConfigFileData } =
    useQuery(
      ['getGenCIFileData'],
      () => getGenCIFileData(globalstate$.value?.plantId),
      {
        onSuccess: (response) => {
          if (response?.data?.length > 0) {
            setAllFilesData(response?.data);
            setIsAnyDocumentUploaded(true);
            const configFilesData = response?.data.find(
              (item) => item.entityHierarchy === entityHierarchy,
            );
            if (configFilesData && Object.keys(configFilesData).length) {
              setHierarchyFilesData(configFilesData);
              setFileStatus(configFilesData?.files[0]?.status);
            } else {
              setHierarchyFilesData({});
              setFileStatus('');
            }
          }
        },
        onError: () => {},
        refetchOnWindowFocus: false,
        retry: false,
        refetchInterval: 15000,
      },
    );

  useEffect(() => {
    if (entityHierarchy) {
      const configFilesData = allFilesData?.length
        ? allFilesData.find((item) => item.entityHierarchy === entityHierarchy)
        : {};
      if (configFilesData && Object.keys(configFilesData).length) {
        setHierarchyFilesData(configFilesData);
        setFileStatus(configFilesData?.files[0]?.status);
      } else {
        setHierarchyFilesData({});
        setFileStatus('');
      }
    }
  }, [entityHierarchy, allFilesData]);

  useEffect(() => {
    if (allConfigFileAPIData?.data?.length && hierarchyAPIData?.data) {
      const mergedData = hierarchyAPIData?.data;
      mergeHierarchyAndConfigFileData(mergedData, allConfigFileAPIData?.data);
      setHierarchyData(mergedData);
    }
  }, [hierarchyAPIData, allConfigFileAPIData]);

  useEffect(() => {
    setIsExpanded(enableAccordian && genCIStatus);
  }, [enableAccordian, genCIStatus]);

  return (
    <Accordion
      id="data-upload-and-mapping"
      expanded={isExpanded}
      disabled={!(enableAccordian && genCIStatus)}
      onChange={(_e, expanded) => {
        if (expanded) {
          setIsExpanded(expanded);
        }
        setIsExpanded(expanded);
      }}
    >
      <AccordionSummary
        aria-controls="data-upload-and-mapping-content"
        data-testid="data-upload-and-mapping-content"
        expandIcon={<DownArrow alt="down arrow" height={9} width={16} />}
      >
        <DataUploadAndMappingLabelContainer flexGrow={1} whiteSpace="nowrap">
          {MESSAGE_STRINGS.DATA_UPLOAD_AND_MAPPING}
        </DataUploadAndMappingLabelContainer>
      </AccordionSummary>
      <AccordionDetails>
        <HierarchyTitle>{MESSAGE_STRINGS.EQUIPMENT_HIERARCHY}</HierarchyTitle>
        <TreeContainer>
          {isHierarchyDataLoading ? (
            <LodingIndicatorContainer data-testid="loading-container">
              <LoadingIndicator size={32} />
            </LodingIndicatorContainer>
          ) : (
            <>
              <StyledTree data-testid="hierarchy-tree">
                {hierarchyData?.entityChildren?.map((childNode) => {
                  return (
                    <RenderTreeData
                      data={childNode}
                      key={`${childNode?.entityId}`}
                      handleHierarchy={handleHierarchy}
                    />
                  );
                })}
              </StyledTree>
              <UploadAndFileStatusContainer>
                <GenCIUploadContainer data-testid="genci-upload-container">
                  <GenCIUpload
                    data-testid="genci-upload-component"
                    entityHierarchy={entityHierarchy}
                    entityName={entityName}
                    fileStatus={fileStatus}
                    refetchConfigFileData={refetchConfigFileData}
                  />
                </GenCIUploadContainer>
                {Object.keys(hierarchyFilesData).length ? (
                  <FileUploadedContainer data-testid="genci-uploaded-file-data">
                    <HeaderContainer>
                      <HeaderLabel
                        variant="h4"
                        data-testid="uploaded-file-header"
                      >
                        {MESSAGE_STRINGS['GenCI.fileUploaded.info']}
                      </HeaderLabel>
                    </HeaderContainer>

                    <ValuesContainer>
                      <ValuesComponent>
                        {hierarchyFilesData?.files &&
                          hierarchyFilesData?.files.map((fileData) => (
                            <Box
                              data-testid="file-name"
                              key={fileData?.fileName}
                            >
                              {fileData?.fileName}
                              <FileStatus data-testid="file-status">
                                {getFileStatusToDisplay(fileData?.status)}
                              </FileStatus>
                            </Box>
                          ))}
                      </ValuesComponent>
                    </ValuesContainer>
                  </FileUploadedContainer>
                ) : null}
              </UploadAndFileStatusContainer>
            </>
          )}
        </TreeContainer>
      </AccordionDetails>
    </Accordion>
  );
};

DataUploadAndMapping.propTypes = {
  enableAccordian: PropTypes.bool,
  genCIStatus: PropTypes.bool,
  setIsAnyDocumentUploaded: PropTypes.func,
};
export default DataUploadAndMapping;
