import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { ACCORDION_STATUS } from '../../constants/en-us';

const StyledAccordionStatusBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.background.gunmetalGrey,
  justifyContent: 'center',
  borderRadius: '0.25rem',
  minWidth: '5.8rem',
  marginLeft: 'auto',
}));

const AccordionStatus = ({ status = ACCORDION_STATUS.NOT_STARTED }) => {
  return (
    <StyledAccordionStatusBox>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '0.875rem',
          lineHeight: '1.188rem',
          fontStyle: 'normal',
        }}
      >
        {status}
      </Typography>
    </StyledAccordionStatusBox>
  );
};

AccordionStatus.propTypes = {
  status: PropTypes.string,
};

export default AccordionStatus;
