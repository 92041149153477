import React from 'react';
import PropTypes from 'prop-types';
import DownArrow from '../../assets/img/downArrow.svg';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './style.js';

function AccordionSummaryFun({ expandIcon = null, isAlert = false, ...props }) {
  return (
    <StyledAccordionSummary
      expandIcon={expandIcon || <DownArrow height="10" width="16" />}
      isAlert={isAlert}
      {...props}
    />
  );
}

AccordionSummaryFun.propTypes = {
  expandIcon: PropTypes.node,
  isAlert: PropTypes.bool,
};

function AccordionDetailsFun({ children, ...other }) {
  return <StyledAccordionDetails {...other}>{children}</StyledAccordionDetails>;
}

AccordionDetailsFun.propTypes = {
  children: PropTypes.node,
};

function CustomizedAccordions(props) {
  const { children, ...other } = props;
  return <StyledAccordion {...other}>{children}</StyledAccordion>;
}
CustomizedAccordions.propTypes = {
  children: PropTypes.node.isRequired,
};
CustomizedAccordions.AccordionSummary = AccordionSummaryFun;
CustomizedAccordions.AccordionDetails = AccordionDetailsFun;
export default CustomizedAccordions;
