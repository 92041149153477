import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogActions,
} from './style.js';

export default function ModalBody({
  isFlex = false,
  dialogTitle = '',
  breakerLine,
  actions = null,
  dialogContent,
  customIcon,
  minWidth = '',
  maxWidth = '',
  minHeight,
  isLargeTitle = false,
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexBasis="100%"
      minWidth={minWidth}
      maxWidth={maxWidth}
      justifyContent="center"
      minHeight={minHeight}
    >
      <Box>{customIcon}</Box>
      <Box display="flex" flexDirection="column" flexBasis="95%">
        <StyledDialogTitle
          className="dialog-title"
          data-testid="modal-header-text"
          isLarge={isLargeTitle}
        >
          {dialogTitle}
        </StyledDialogTitle>
        {breakerLine}
        <StyledDialogContent>{dialogContent}</StyledDialogContent>
        <StyledDialogActions isFlex={isFlex}>{actions}</StyledDialogActions>
      </Box>
    </Box>
  );
}

ModalBody.propTypes = {
  isFlex: PropTypes.bool,
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  breakerLine: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minWidth: PropTypes.string,
  actions: PropTypes.node,
  dialogContent: PropTypes.node,
  customIcon: PropTypes.node,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  isLargeTitle: PropTypes.bool,
};
