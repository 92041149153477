import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { globalstate$ } from '@smf/ui-util-app';
import PropTypes from 'prop-types';
import InfoIcon from '../../assets/img/InfoTooltipIcon.svg';
import Accordion from '../../components/SMFAccordion';
import Button from '../../components/Button';
import * as S from './style';
import SMFSwitch from '../../components/SMFSwitch';
import { TOAST_REDUCER_CONSTANTS, QUERY_CONSTANTS } from '../../constants';
import { useToastContext } from '../../context/toastContext';
import MESSAGE_STRINGS from '../../constants/en-us';
import {
  updateGenCIStatus,
  getSingleDbAutoPromptConfigStatus,
  getVirtualAssistantAutoPromptConfigStatus,
} from '../../utils/apiHelpers';
import { updateRxjsState, useRxjsState } from '../../hooks/useRxjsState';
import {
  isDisableSave,
  settingVirtualAssistantToggleConfingStatus,
} from '../../utils/helpers';

function DisplaySettings({ enableAccordian }) {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const { toastDispatch } = useToastContext();
  const { rxjsState } = useRxjsState();
  const [status, setStatus] = useState(
    globalstate$.value?.configStatus?.genci ?? false,
  );
  const [initialSingleDbAutoPromptStatus, setInitialSingleDbAutoPromptStatus] =
    useState(false);
  const [singleDbAutoPromptStatus, setSingleDbAutoPromptStatus] =
    useState(false);
  const [
    initialVirtualAssistantAutoPromptStatus,
    setInitialVirtualAssistantAutoPromptStatus,
  ] = useState(false);
  const [
    virtualAssistantAutoPromptStatus,
    setVirtualAssistantAutoPromptStatus,
  ] = useState(false);
  const { plantId } = rxjsState;

  // Update Status After common status api call
  useEffect(() => {
    setStatus(globalstate$.value?.configStatus?.genci ?? false);
  }, [globalstate$.value?.configStatus?.genci]);

  // Update API Calling
  const { mutate: updateGenCIStatusAPI, isLoading: updateGenCIConfigLoading } =
    useMutation(
      ['update-genci-config'],
      async (payload) => {
        const result = await updateGenCIStatus(payload);
        return result;
      },
      {
        onSuccess: (_, variables) => {
          // update spc status in Rxjs State
          updateRxjsState({
            configStatus: {
              ...rxjsState?.configStatus,
              genci: variables.genciStatus,
            },
          });
          // Success Toaster
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE,
            },
          });
          setInitialSingleDbAutoPromptStatus(singleDbAutoPromptStatus);
          setInitialVirtualAssistantAutoPromptStatus(
            virtualAssistantAutoPromptStatus,
          );
        },
        onError: (err) => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
            },
          });
          console.error('Error while updating GenCI config', err);
        },
        enabled: !!plantId,
      },
    );

  useQuery({
    queryKey: [QUERY_CONSTANTS.GET_SINGLEDB_AUTO_PROMPT_CONFIG_STATUS],
    queryFn: () => {
      return getSingleDbAutoPromptConfigStatus(rxjsState?.plantId);
    },
    onSuccess: (res) => {
      setInitialSingleDbAutoPromptStatus(res.configuredAutoSuggestionStatus);
      setSingleDbAutoPromptStatus(res.configuredAutoSuggestionStatus);
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(rxjsState?.plantId),
  });

  useQuery({
    queryKey: [QUERY_CONSTANTS.GET_VA_AUTO_PROMPT_CONFIG_STATUS],
    queryFn: () => {
      return getVirtualAssistantAutoPromptConfigStatus();
    },
    onSuccess: (res) => {
      settingVirtualAssistantToggleConfingStatus(
        setInitialVirtualAssistantAutoPromptStatus,
        setVirtualAssistantAutoPromptStatus,
        res,
      );
    },
    refetchOnWindowFocus: false,
  });

  const onSaveClick = () => {
    updateGenCIStatusAPI({
      factoryId: plantId,
      genciStatus: status,
      isSingleDbSuggestionEnabled: singleDbAutoPromptStatus,
      isVASuggestionEnabled: virtualAssistantAutoPromptStatus,
    });
  };

  return (
    <Accordion defaultExpanded={enableAccordian} disabled={!enableAccordian}>
      <AccordionSummary data-testid="genci-enable-disable-container">
        {MESSAGE_STRINGS.DISPLAY_SETTINGS}
      </AccordionSummary>
      <AccordionDetails>
        <S.ConfigRow>
          <S.OuterFlexContainer>
            <S.InnerFlexContainer>
              <S.InfoIconWrapper>
                <S.StyledTooltip
                  title={MESSAGE_STRINGS.GENCI_ENABLE_TOOLTIP}
                  data-testid="genci-config-tooltip"
                  placement="bottom-start"
                >
                  <S.StyleIconButton>
                    <InfoIcon
                      height="1.5rem"
                      width="1.5rem"
                      data-testid="genci-config-enable-toolip-icon"
                    />
                  </S.StyleIconButton>
                </S.StyledTooltip>
              </S.InfoIconWrapper>
              <S.Description>{MESSAGE_STRINGS.ENABLE_GENCI}</S.Description>
              <S.SwitchContainer>
                <SMFSwitch
                  checked={status}
                  onClick={() => {
                    setStatus((previousState) => !previousState);
                  }}
                />
              </S.SwitchContainer>
            </S.InnerFlexContainer>
          </S.OuterFlexContainer>
        </S.ConfigRow>
        <S.ConfigRow>
          <S.OuterFlexContainer>
            <S.InnerFlexContainer>
              <S.InfoIconWrapper>
                <S.StyledTooltip
                  title={MESSAGE_STRINGS.SINGLEDB_AUTO_PROMPT_ENABLE_TOOLTIP}
                  data-testid="singleDb-auto-prompt-config-tooltip"
                  placement="bottom-start"
                >
                  <S.StyleIconButton>
                    <InfoIcon
                      height="1.5rem"
                      width="1.5rem"
                      data-testid="singleDb-auto-prompt-config-enable-toolip-icon"
                    />
                  </S.StyleIconButton>
                </S.StyledTooltip>
              </S.InfoIconWrapper>
              <S.Description>
                {MESSAGE_STRINGS.ENABLE_SINGLEDB_AUTO_PROMPT}
              </S.Description>
              <S.SwitchContainer>
                <SMFSwitch
                  checked={singleDbAutoPromptStatus}
                  onClick={() => {
                    setSingleDbAutoPromptStatus(
                      (previousState) => !previousState,
                    );
                  }}
                />
              </S.SwitchContainer>
            </S.InnerFlexContainer>
          </S.OuterFlexContainer>
        </S.ConfigRow>
        <S.ConfigRow>
          <S.OuterFlexContainer>
            <S.InnerFlexContainer>
              <S.InfoIconWrapper>
                <S.StyledTooltip
                  title={MESSAGE_STRINGS.VA_AUTO_PROMPT_ENABLE_TOOLTIP}
                  data-testid="virtual-assistant-auto-prompt-config-tooltip"
                  placement="bottom-start"
                >
                  <S.StyleIconButton>
                    <InfoIcon
                      height="1.5rem"
                      width="1.5rem"
                      data-testid="virtual-assistant-auto-prompt-config-enable-toolip-icon"
                    />
                  </S.StyleIconButton>
                </S.StyledTooltip>
              </S.InfoIconWrapper>
              <S.Description>
                {MESSAGE_STRINGS.ENABLE_VA_AUTO_PROMPT}
              </S.Description>
              <S.SwitchContainer>
                <SMFSwitch
                  checked={virtualAssistantAutoPromptStatus}
                  onClick={() => {
                    setVirtualAssistantAutoPromptStatus(
                      (previousState) => !previousState,
                    );
                  }}
                />
              </S.SwitchContainer>
            </S.InnerFlexContainer>
          </S.OuterFlexContainer>
        </S.ConfigRow>
        <S.SaveButtonContainer>
          <Button
            onClick={onSaveClick}
            disabled={
              updateGenCIConfigLoading ||
              isDisableSave(
                rxjsState,
                status,
                initialSingleDbAutoPromptStatus,
                singleDbAutoPromptStatus,
                initialVirtualAssistantAutoPromptStatus,
                virtualAssistantAutoPromptStatus,
              )
            }
            data-testid="saveButton"
          >
            {MESSAGE_STRINGS.SAVE}
          </Button>
        </S.SaveButtonContainer>
      </AccordionDetails>
    </Accordion>
  );
}

DisplaySettings.propTypes = {
  enableAccordian: PropTypes.bool,
};

export default DisplaySettings;
