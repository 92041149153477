import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactDOM from 'react-dom/client';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  // eslint-disable-next-line no-unused-vars
  errorBoundary: (_err, _info, _props) => {
    return null;
  },
  renderType: 'createRoot',
});

export const { bootstrap, mount, unmount } = lifecycles;
