import { Box, styled, Typography } from '@mui/material';

const CustomizedDropZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUploadFromBoxEnabled',
})(({ theme, isUploadFromBoxEnabled }) => ({
  border: `.1rem dashed ${theme.customColors.saveGreen}`,
  borderRadius: '0.5rem',
  color: theme.palette.text.primary,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingY: '1rem',
  '&:hover': {
    cursor: isUploadFromBoxEnabled ? 'pointer' : 'inherit',
  },
}));

const UploadTitle = styled(Typography)(() => ({
  margin: '0.65rem',
}));

const InfoContentContainer = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  minWidth: 475,
  color: theme.palette.text.blockGrey,
  marginTop: '0.5rem',
}));

const UploadLoadingContentContainer = styled(Typography)(() => ({
  marginLeft: '1.25rem',
}));

const InfoIconContainer = styled('section')(() => ({
  margin: '0.56rem',
}));

const InfoContainer = styled(Box)(() => ({
  display: 'flex',
  width: '31.25rem',
}));

export {
  CustomizedDropZone,
  UploadTitle,
  InfoContentContainer,
  UploadLoadingContentContainer,
  InfoIconContainer,
  InfoContainer,
};
