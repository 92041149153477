import { styled } from '@mui/material/styles';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  '& .MuiTreeItem-content > .MuiTreeItem-iconContainer': {
    paddingLeft: '0.4rem',
  },
  '& .MuiTreeItem-root': {
    marginBottom: '0em ',
  },
  '& .MuiTreeItem-content': {
    padding: '0.625rem 0rem',
    cursor: 'default',
  },
  '& .MuiTreeItem-label:hover': {
    background: 'transparent',
    cursor: 'pointer',
  },
  '& .MuiTreeItem-content.Mui-selected': {
    background: theme.palette.background.configLighterDark,
  },
  '& .MuiTreeItem-content.Mui-selected.Mui-focused': {
    background: theme.palette.background.configLighterDark,
  },
  '& .MuiTreeItem-content.Mui-selected:hover': {
    backgroundColor: theme.palette.background.configLighterDark,
    cursor: 'pointer',
  },
  '&.Mui-selected > .MuiTreeItem-content': {
    background: theme.palette.background.configLighterDark,
  },
  '& .MuiTreeItem-content.Mui-focused': {
    backgroundColor: 'transparent',
  },
}));

export default CustomTreeItem;
