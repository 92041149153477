import { styled } from '@mui/material/styles';
import { Box, alpha, Typography } from '@mui/material';
import { darkPalette } from '../../__mocks__/darkTheme';

const TreeContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.configLighterDark,
  borderBottom: `1px solid ${alpha(theme.customColors.black, 0.125)}`,
  paddingRight: '1.5rem',
  display: 'flex',
  height: '58rem',
}));

const StyledTree = styled(Box)(() => ({
  backgroundColor: darkPalette.grey.grey100,
  minWidth: '35%',
  overflowY: 'scroll',
  paddingLeft: '1rem',
}));

const HierarchyTitle = styled(Box)(({ theme }) => ({
  minHeight: '3.5rem',
  backgroundColor: theme.palette.background.darkishBlackBlue,
  borderBottom: `1px solid ${alpha(theme.customColors.black, 0.125)}`,
  paddingLeft: '1.25rem',
  paddingRight: '1.5rem',
  alignItems: 'center',
  display: 'flex',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.darkishBlackBlue,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingLeft: '1rem',
  minHeight: '3.5rem',
  border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
  borderBottom: 'none',
  borderRadius: '.25rem .25rem 0 0',
}));

const HeaderLabel = styled(Typography)(() => ({
  fontSize: '1rem',
}));

const ValuesContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.blackGrey,
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '0.5rem 1rem',
  border: `.0625rem solid ${theme.palette.background.wrapperGrey}`,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  minHeight: '3.5rem',
}));

const ValuesComponent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const FileStatus = styled(Box)(({ theme }) => ({
  background: theme.palette.background.gunmetalGrey,
  padding: '0.4rem 0.5rem',
  borderRadius: '0.4rem',
  marginLeft: '2rem',
  display: 'inline',
}));

const FileUploadedContainer = styled(Box)(() => ({
  marginTop: '9rem',
  width: '100%',
  padding: '0rem 1.5rem 0 3rem',
}));

const LodingIndicatorContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
}));

const DataUploadAndMappingLabelContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const GenCIUploadContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const UploadAndFileStatusContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
}));

export {
  TreeContainer,
  StyledTree,
  HierarchyTitle,
  HeaderLabel,
  HeaderContainer,
  ValuesContainer,
  ValuesComponent,
  FileStatus,
  FileUploadedContainer,
  LodingIndicatorContainer,
  DataUploadAndMappingLabelContainer,
  GenCIUploadContainer,
  UploadAndFileStatusContainer,
};
