import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Button from '../Button';

const Actions = ({
  confirmText,
  cancelText,
  handleClose,
  handleSave,
  isSaveDisabled = false,
}) => {
  return (
    <Box>
      <Button
        buttonType="secondary"
        onClick={handleClose}
        text={cancelText}
        data-testid="secondary-modal-button"
      />
      <Button
        buttonType="primary"
        onClick={handleSave}
        text={confirmText}
        data-testid="primary-modal-button"
        disabled={isSaveDisabled}
      />
    </Box>
  );
};

Actions.propTypes = {
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  isSaveDisabled: PropTypes.bool,
};

export default Actions;
