import React from 'react';
import PropTypes from 'prop-types';
import { MESSAGE_STRINGS } from './constants';
import {
  SnackBarContent,
  CustomizedToast,
  SlideTransitionComponent,
} from './style';

const Toast = ({
  open = false,
  type = 'SUCCESS',
  message = MESSAGE_STRINGS['Toast.message.SUCCESS'],
  vertical = 'bottom',
  horizontal = 'center',
  direction = 'right',
  ...others
}) => (
  <CustomizedToast
    type={type}
    anchorOrigin={{ vertical, horizontal }}
    open={open}
    TransitionComponent={(props) =>
      SlideTransitionComponent({ ...props, direction })
    }
    data-testid={`toast-${type}`}
    message={<SnackBarContent message={message} type={type} />}
    {...others}
  />
);

Toast.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.node,
};

export default Toast;
