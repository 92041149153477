import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from '@mui/material';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  placeSelf: 'center',
  boxShadow: 'none',
  margin: '0 0 0.313rem 0',
  background: theme.palette.background.blackGrey,
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: '0 0 0.313rem 0',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'isAlert',
})(({ theme, isAlert }) => ({
  minHeight: '3.5rem',
  backgroundColor: theme.palette.background.darkishBlackBlue,
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
  paddingLeft: '1.25rem',
  paddingRight: '1.5rem',
  borderRadius: '0.25rem',
  height: isAlert ? '' : '3.5rem',
  fontWeight: '800',
  '&$expanded': {
    minHeight: '3.5rem',
  },
  '.MuiAccordionSummary-content': {
    alignItems: 'center',
    marginRight: '1rem',
    marginTop: '1.156rem',
    marginBottom: '1.156rem',
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '1.156rem 1rem 1.156rem 0',
  },
  '&.Mui-disabled': {
    color: theme.palette.text.darkGrey,
    opacity: 1,
    '.MuiAccordionSummary-content': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      filter:
        'brightness(0) saturate(100%) invert(35%) sepia(3%) saturate(630%) hue-rotate(175deg) brightness(96%) contrast(98%)',
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.darkishBlue,
  padding: '0',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

export { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails };
