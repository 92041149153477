import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../constants';
import {
  DEFAULT_COLOR,
  DEFAULT_SIZE,
  DEFAULT_VARIANT_CONTAINED,
} from './constants';
import * as S from './style';

const Button = forwardRef(
  (
    {
      type,
      buttonType = BUTTON_TYPE.PRIMARY,
      variant = DEFAULT_VARIANT_CONTAINED,
      size = DEFAULT_SIZE,
      onClick,
      color = DEFAULT_COLOR,
      disabled,
      text = BUTTON_TYPE.PRIMARY,
      children,
      buttonSize = BUTTON_SIZE.STANDARD,
      ...rest
    },
    ref,
  ) => {
    return (
      <S.Button
        type={type}
        buttonType={buttonType}
        variant={variant}
        size={size}
        color={color}
        onClick={onClick}
        disabled={disabled || false}
        buttonSize={buttonSize}
        ref={ref}
        data-testid="general-button"
        {...rest}
      >
        {children || <S.ButtonTypography>{text}</S.ButtonTypography>}
      </S.Button>
    );
  },
);

Button.propTypes = {
  type: PropTypes.string,
  buttonType: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonSize: PropTypes.string,
};

export default Button;
