export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const MESSAGE_STRINGS = {
  'Toast.message.SUCCESS': 'Your changes were saved Successfully!',
  'Toast.message.ERROR': 'Oops, something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',
};
