import React from 'react';
import PropTypes from 'prop-types';
import ErrorFileStatusIcon from '../../assets/img/errorFileStatusIcon.svg';
import FileSucessStatusIcon from '../../assets/img/fileSucessStatusIcon.svg';
import { FILE_STATUS } from '../../constants/index';
import CustomCircularProgress from './style.js';

const StatusIcon = ({ fileStatus }) => {
  if (fileStatus === FILE_STATUS.IN_PROGRESS) {
    return <CustomCircularProgress data-testid="in-progress-icon" size={16} />;
  }
  if (fileStatus === FILE_STATUS.COMPLETED) {
    return (
      <FileSucessStatusIcon data-testid="success-icon" width={16} height={16} />
    );
  }
  if (fileStatus === FILE_STATUS.ERROR) {
    return (
      <ErrorFileStatusIcon data-testid="failed-icon" width={16} height={16} />
    );
  }
  return null;
};

StatusIcon.propTypes = {
  fileStatus: PropTypes.string,
};

export default StatusIcon;
