import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import StyledTooltip from './style';

const EllipsisTooltip = ({ text }) => {
  const textRef = useRef(null);
  const [isTextEllipsed, setIsTextEllipsed] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTextEllipsed(
        textRef.current.scrollWidth > textRef.current.clientWidth,
      );
    }
  }, [text]);

  const renderTooltip = isTextEllipsed ? (
    <StyledTooltip title={text} placement="top-start">
      <span>{text}</span>
    </StyledTooltip>
  ) : (
    <span>{text}</span>
  );

  return (
    <Box
      ref={textRef}
      sx={{
        maxWidth: '17rem',
        height: '1.25rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block',
        whiteSpace: 'nowrap',
      }}
    >
      {renderTooltip}
    </Box>
  );
};

EllipsisTooltip.propTypes = {
  text: PropTypes.string,
};

export default EllipsisTooltip;
