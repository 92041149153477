import { InputBase as InputBaseComponent, Select } from '@mui/material';
import { styled } from '@mui/system';
import ErrorIconComponent from '../../assets/img/smallErrorIcon.svg';

export const ComponentContainer = styled('div')(({ width }) => ({
  width: width || 'unset',
}));

export const InputBase = styled(InputBaseComponent, {
  shouldForwardProp: (prop) => prop !== 'borderRadius' && prop !== 'error',
})(({ theme, borderRadius: dropdownRadius, error }) => ({
  width: '100%',
  '& .Mui-disabled': {
    color: theme.palette.background.shuttleGray,
  },
  color: theme.palette.background.shuttleGray,
  '& .MuiSelect-iconOutlined': {
    marginRight: '0.5rem',
    top: 'inherit',
  },
  '& .MuiSelect-icon.Mui-disabled': {
    filter: 'opacity(0.5)',
    '& path': { fill: theme.palette.background.shuttleGray },
  },
  '& .MuiInputBase-input': {
    border: `.0625rem solid ${
      error ? theme.palette.text.errorText : theme.palette.border.darkGrey
    }`,
    width: '100%',
    paddingLeft: '1rem',
    fontSize: '0.875rem',
    '&.MuiSelect-select': {
      borderRadius: dropdownRadius,
      backgroundColor: theme.palette.background.configLighterDark,
      color: theme.customColors.white,
      '&.Mui-disabled': {
        color: theme.palette.background.shuttleGray,
      },
    },
    '&:focus': {
      border: `.0625rem solid ${theme.palette.selected.greenSelect}`,
    },
    '&[aria-expanded=true]': {
      border: `.0625rem solid ${theme.palette.selected.greenSelect}`,
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: 'inherit', // Was overriding given disabled color
    },
  },
  '& .Mui-error': {
    borderColor: theme.palette.text.errorText,
    '&:focus': {
      borderColor: theme.palette.text.errorText,
    },
  },
}));

const paperStyleOverrides = {
  '& .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root:hover,.MuiMenuItem-root.Mui-selected:hover ':
    {
      backgroundColor: (theme) => theme.palette.background.gunmetalGrey,
    },
};

const menuStyles = { maxHeight: '15.75rem' };

export const CustomizedSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'isDefaultValue',
})(({ theme, isDefaultValue, label }) => ({
  marginTop: label ? '0.5rem' : 'unset',
  width: '100%',
  color: isDefaultValue
    ? theme.palette.text.mediumLightCyanBlue
    : theme.palette.text.primary,
  height: '2rem',
  '.MuiInputBase-input.MuiSelect-select': {
    color: isDefaultValue
      ? theme.palette.text.shuttleGray
      : theme.palette.text.primary,
  },
}));

export const SelectMenuStyleProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  PaperProps: {
    sx: paperStyleOverrides,
  },
  sx: menuStyles,
};

export const ErrorTextContainer = styled('div')(({ errorTextWidth }) => ({
  fontSize: '0.875rem',
  marginTop: '0.4375rem',
  display: 'flex',
  alignItems: 'center',
  maxWidth: errorTextWidth || 'unset',
}));

export const ErrorIcon = styled(ErrorIconComponent)(() => ({
  marginRight: '0.56rem',
}));

export const RequiredStar = styled('span')(({ theme }) => {
  const { text } = theme?.palette || {};

  return {
    color: text.errorText,
    marginLeft: '0.25rem',
  };
});

export const Label = styled('label')(() => ({
  fontSize: '0.875rem',
  textTransform: 'capitalize',
}));
