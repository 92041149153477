import { find } from 'lodash';
import MESSAGE_STRINGS from '../constants/en-us';
import { FILE_STATUS, DATA_SOURCES } from '../constants/index';

/**
 * A function which returns accept object for file type
 * @param {string} type File Type for accepting
 * @returns AcceptType Object
 */
export function getAcceptType(type = 'PDF') {
  if (type === 'PDF') {
    return { 'application/pdf': ['.pdf'] };
  }
  return {};
}

export const getNodeIds = (hierarchy, result = []) => {
  result.push(`${hierarchy.entityId}`);
  if (hierarchy?.entityChildren) {
    hierarchy?.entityChildren.forEach((child) => {
      getNodeIds(child, result);
    });
  }
  return result;
};

export const mergeHierarchyAndConfigFileData = (
  hierarchy,
  allConfigFilesData,
) => {
  const entityHierarchy = hierarchy?.entityHierarchy;
  const entityObj = find(allConfigFilesData, { entityHierarchy });
  if (entityObj) {
    hierarchy.fileStatus = entityObj?.files?.[0]?.status;
  }
  if (hierarchy?.entityChildren?.length) {
    hierarchy?.entityChildren?.forEach((entity) => {
      mergeHierarchyAndConfigFileData(entity, allConfigFilesData);
    });
  }
};

export const getInfoContentByFileStatus = (fileStatus) => {
  if (
    fileStatus === FILE_STATUS.IN_PROGRESS ||
    fileStatus === FILE_STATUS.COMPLETED
  ) {
    return MESSAGE_STRINGS['GenCI.progressInfo.content'];
  }
  if (fileStatus === FILE_STATUS.ERROR) {
    return MESSAGE_STRINGS['GenCI.errorInfo.content'];
  }
  return MESSAGE_STRINGS['GenCI.uploadInfo.content'];
};

export const getFileStatusToDisplay = (fileStatus) => {
  if (fileStatus === FILE_STATUS.IN_PROGRESS) {
    return MESSAGE_STRINGS.FILE_STATUS_TO_DISPLAY.IN_PROGRESS;
  }
  if (fileStatus === FILE_STATUS.COMPLETED) {
    return MESSAGE_STRINGS.FILE_STATUS_TO_DISPLAY.COMPLETED;
  }
  return MESSAGE_STRINGS.FILE_STATUS_TO_DISPLAY.ERROR;
};

export function getTemperatureValue(llmFineTuningData) {
  if (llmFineTuningData?.fineTuningConfiguredValues?.temperature) {
    return llmFineTuningData.fineTuningConfiguredValues.temperature;
  }
  return llmFineTuningData?.fineTuningDefaultValues?.temperature;
}

export function getTopPValue(llmFineTuningData) {
  if (llmFineTuningData?.fineTuningConfiguredValues?.top_p) {
    return llmFineTuningData.fineTuningConfiguredValues.top_p;
  }
  return llmFineTuningData?.fineTuningDefaultValues?.top_p;
}

export function getKValue(llmFineTuningData) {
  if (llmFineTuningData?.fineTuningConfiguredValues?.k) {
    return llmFineTuningData.fineTuningConfiguredValues.k;
  }
  return llmFineTuningData?.fineTuningDefaultValues?.k;
}

export function getPrompts(prompts) {
  return prompts?.split('\n')?.filter((x) => x.trim() !== '');
}

export function isDisableSave(
  rxjsState,
  status,
  initialSingleDbAutoPromptStatus,
  singleDbAutoPromptStatus,
  initialVirtualAssistantAutoPromptStatus,
  virtualAssistantAutoPromptStatus,
) {
  return (
    rxjsState?.configStatus?.genci === status &&
    initialSingleDbAutoPromptStatus === singleDbAutoPromptStatus &&
    initialVirtualAssistantAutoPromptStatus === virtualAssistantAutoPromptStatus
  );
}

export function resChangeOnDocumentUploadFeatureFlag(res, splitTreatments) {
  if (splitTreatments?.GenCI_document_upload_menu?.treatment === 'off') {
    res.dataSources = res.dataSources.filter(
      (dataSource) => dataSource !== DATA_SOURCES.DOC_UPLOAD,
    );
    res.selectedDataSource = DATA_SOURCES.DMS;
  }
  return res;
}

export function settingVirtualAssistantToggleConfingStatus(
  setInitialVirtualAssistantAutoPromptStatus,
  setVirtualAssistantAutoPromptStatus,
  res,
) {
  setInitialVirtualAssistantAutoPromptStatus(
    res.configuredAutoSuggestionStatus,
  );
  setVirtualAssistantAutoPromptStatus(res.configuredAutoSuggestionStatus);
}
