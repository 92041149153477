import { Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    borderColor: theme.palette.background.blackGrey,
    backgroundColor: theme.palette.background.elevationGrey,
    padding: '1.5rem',
    color: theme.palette.text.primary,
    outline: 'none',
    borderRadius: '0.5rem',
    width: '30rem',
    margin: '0rem',
  },
}));

export default StyledPaper;
