import PropTypes from 'prop-types';
import { alpha, Dialog, styled } from '@mui/material';
import GeneralModalPaper from '../GeneralModalPaper';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderColor: theme.palette.background.blackGrey,
  background: alpha(theme.palette.background.paper, 0.8),
}));

export default function GeneralDialog({
  open,
  fullWidth = true,
  maxWidth = 'sm',
  children,
  ...other
}) {
  return (
    <StyledDialog
      data-testid="general-dialog-component"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperComponent={GeneralModalPaper}
      {...other}
    >
      {children}
    </StyledDialog>
  );
}

GeneralDialog.propTypes = {
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
};
