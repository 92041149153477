import React from 'react';
import PropTypes from 'prop-types';
import { styled, Tooltip, tooltipClasses } from '@mui/material';

const CustomizedTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'textSize' &&
      prop !== 'maxWidth' &&
      prop !== 'isBorderEnabled' &&
      prop !== 'background',
  },
)(
  ({
    theme,
    maxWidth: toolTipMaxWidth,
    textSize,
    background,
    isBorderEnabled,
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: background ?? theme.palette.background.eerieBlack,
      maxWidth: toolTipMaxWidth,
      fontSize: textSize,
      lineHeight: '1rem',
      fontWeight: 400,
      borderRadius: '0.25em',
      padding: '.75rem',
      color: theme.palette.text.lightYellowishGray,
      border: isBorderEnabled
        ? `.0625rem solid ${theme.palette.background.metalGrey}`
        : 'none',
    },
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: 30,
      color: background ?? theme.palette.background.eerieBlack,
    },
  }),
);
function GeneralTooltip({
  message,
  children,
  maxWidth = '18.75rem',
  placement = 'bottom-start',
  textSize = '0.75rem',
  background,
  isBorderEnabled = false,
  forceRenderTooltip = false,
  ...other
}) {
  if (!message && !forceRenderTooltip) {
    return children;
  }
  return (
    <CustomizedTooltip
      title={message}
      {...other}
      placement={placement}
      maxWidth={maxWidth}
      textSize={textSize}
      background={background}
      isBorderEnabled={isBorderEnabled}
    >
      <span> {children}</span>
    </CustomizedTooltip>
  );
}
GeneralTooltip.propTypes = {
  message: PropTypes.node,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  placement: PropTypes.string,
  background: PropTypes.string,
  hasSpacing: PropTypes.bool,
  textSize: PropTypes.string,
  isBorderEnabled: PropTypes.bool,
  forceRenderTooltip: PropTypes.bool,
};
export default GeneralTooltip;
