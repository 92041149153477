import {
  styled,
  Typography,
  Tooltip,
  Box,
  tooltipClasses,
  IconButton,
} from '@mui/material';

export const DataSourceContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  padding: '0.75rem 1rem',
  paddingTop: '0.125rem',
}));

export const DataSourceContainerLabel = styled(Typography)(({ theme }) => ({
  color: theme.customColors.white,
  fontSize: '0.875rem',
  fontWeight: 400,
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: `0.06rem solid ${theme.customColors.metalGrey}`,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '.625rem 0 .625rem .625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '18.06rem',
  },
}));

export const InfoIconWrapper = styled(Box)(() => ({
  margin: '0.625rem',
}));

export const StyleIconButton = styled(IconButton)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));
