import { forwardRef } from 'react';
import { Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import FileUploadIcon from '../../assets/img/fileUploadIcon.svg';
import InfoIcon from '../../assets/img/infoIcon.svg';
import { getAcceptType } from '../../utils/helpers';
import MESSAGE_STRINGS from '../../constants/en-us';
import LoadingIndicator from '../LoadingIndicator';
import {
  CustomizedDropZone,
  UploadTitle,
  InfoContentContainer,
  UploadLoadingContentContainer,
  InfoIconContainer,
  InfoContainer,
} from './style.js';

function getDropzoneProps(isPropEnabled, getRootProps) {
  return isPropEnabled ? getRootProps({ className: 'dropzone' }) : {};
}

const UploadFile = forwardRef((props, ref) => {
  const {
    entityHierarchy = '',
    uploadTitle = '',
    isFileUploadError = false,
    fileType = 'PDF',
    getUploadUrl = () => null,
    isLoading = false,
    isPreviewComponentEnabled = false,
    infoContent,
    children,
  } = props;
  function onDropAccepted(acceptedFilesData) {
    getUploadUrl(acceptedFilesData[0]);
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
    accept: getAcceptType(fileType),
  });
  const isUploadFromBoxEnabled =
    (entityHierarchy && !isLoading) || isFileUploadError;
  return (
    <Box display="flex" flexDirection="column" height="100%" ref={ref}>
      {!isPreviewComponentEnabled ? (
        <Box
          display="flex"
          flexDirection="column"
          height="23.125rem"
          justifyContent="center"
          alignItems="center"
        >
          <CustomizedDropZone
            data-testid="primary-upload-button"
            {...getDropzoneProps(isUploadFromBoxEnabled, getRootProps)}
            width={500}
            minHeight="14.75rem"
            isUploadFromBoxEnabled={isUploadFromBoxEnabled}
          >
            {(!isLoading || isFileUploadError) && (
              <>
                <input {...getInputProps()} />
                <FileUploadIcon
                  width={50}
                  height={50}
                  data-testid="upload_genci_Icon"
                />
                <UploadTitle variant="subtitle1">{uploadTitle}</UploadTitle>
              </>
            )}
            {isLoading && (
              <Box display="flex" alignItems="center">
                <LoadingIndicator size={32} />
                <UploadLoadingContentContainer>
                  {MESSAGE_STRINGS['UploadComponent.loading']}
                </UploadLoadingContentContainer>
              </Box>
            )}
          </CustomizedDropZone>
          <InfoContainer>
            <InfoIconContainer>
              <InfoIcon
                width={16}
                height={16}
                margin={9}
                data-testid="upload_genci_info_Icon"
              />
            </InfoIconContainer>
            <InfoContentContainer data-testid="upload-info-message">
              {infoContent}
            </InfoContentContainer>
          </InfoContainer>
        </Box>
      ) : (
        children
      )}
    </Box>
  );
});

UploadFile.propTypes = {
  entityHierarchy: PropTypes.string,
  uploadTitle: PropTypes.string,
  isFileUploadError: PropTypes.bool,
  fileType: PropTypes.string,
  getUploadUrl: PropTypes.func,
  isLoading: PropTypes.bool,
  infoContent: PropTypes.string,
  isPreviewComponentEnabled: PropTypes.bool,
  children: PropTypes.node,
};

export default UploadFile;
